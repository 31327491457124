import { ClassTeam } from './../pages/tourDetails/TournamentClasses/components/ClassesTag/ClassesTag';
import { ClassValues } from '../pages/tourDetails/TournamentClasses/components/ClassesTag/ClassesTag';
import { ClassFormatEnum, ClassTypeEnum, SettingsRankingTypeEnum } from '../types/enums';
import { TournamentCategory, User } from '../types/tournament';
import { generateTeamName } from './generateTeamName';

const getBool = (arg: string | boolean): boolean => {
    if (typeof arg === 'string') return arg === 'true';

    return arg;
};

const getNumberBool = (arg: string | number | boolean): boolean => {
    if (typeof arg === 'string') return arg === 'true';
    if (typeof arg === 'number') return arg === 1;

    return arg;
};

// TO DO: Working. Added double translation for safety. Rework after demo
export const classTagTypeConverter = (
    classValues: ClassValues,
    classCollection: Array<ClassValues>
) => {
    return {
        classes: classCollection.map((data) => {
            const isNewClass = typeof data.classId === 'string';
            const { classId, ...rest } = data;

            if (classValues.teams.length) {
                classValues.teams = classValues.teams.map(({ shuffled, ...item }) => {
                    return {
                        ...item,
                        id: typeof item.id === 'number' ? item.id : undefined,
                        seed: Number(item.seed),
                    } as ClassTeam;
                });
            }

            if (classValues.tournamentCategories.length) {
                classValues.tournamentCategories = classValues.tournamentCategories.map((item) => {
                    return {
                        ...item,
                        id: typeof item.id === 'number' ? item.id : undefined,
                        isActive: getNumberBool(item.isActive),
                    } as TournamentCategory;
                });
            }

            if (data.classId === classValues.classId && !isNewClass) {
                return {
                    id: classId,
                    name: classValues.name,
                    type: classValues.type,
                    isHasSubClasses: classValues.isHasSubClasses,
                    isDupr: getBool(classValues.isDupr),
                    requiresLicense: getBool(classValues.requiresLicense),
                    eliminationDraw: Number(classValues.eliminationDraw),
                    minPlayers: Number(classValues.minPlayers),
                    maxPlayers: Number(classValues.maxPlayers),
                    keywordSurface: classValues.keywordSurface,
                    keywordBall: classValues.keywordBall,
                    isOnSale: getBool(classValues.isOnSale),
                    isRanking: getBool(classValues.isRanking),
                    rankingType:
                        Number(classValues.rankingType) || SettingsRankingTypeEnum.RANKING_300p,
                    numberOfGroups: Number(classValues.numberOfGroups),
                    format: classValues.format,
                    roundScoring: getBool(classValues.roundScoring),
                    price: getBool(classValues.isOnSale) ? Number(classValues.price) : null,
                    vat: getBool(classValues.isOnSale) ? Number(classValues.vat) : null,
                    salesStatus: getBool(classValues.isOnSale)
                        ? getBool(classValues.salesStatus)
                        : false,
                    stateOfClass: getBool(classValues.stateOfClass),
                    teams: classValues.teams.map((team: any) => {
                        team.users = team.users.filter((v: any, index: number) => {
                            const maxLength =
                                classValues.type === ClassTypeEnum.SINGLES
                                    ? 1
                                    : classValues.type === ClassTypeEnum.DOUBLES
                                    ? 2
                                    : 999;
                            return index < maxLength;
                        });
                        if (typeof team.id === 'number') {
                            return { ...team, seed: Number(team.seed) };
                        } else {
                            const { id, ...rest } = team;
                            return { ...rest, seed: Number(rest.seed) };
                        }
                    }),
                    tournamentCategories: classValues.tournamentCategories.map((item) => {
                        return {
                            ...item,
                            id: typeof item.id === 'number' ? item.id : undefined,
                            isActive: getNumberBool(item.isActive),
                        } as TournamentCategory;
                    }),
                    tournamentMatches: classValues.tournamentMatches,
                };
            }
            if (data.classId === classValues.classId && isNewClass) {
                return {
                    name: classValues.name,
                    type: classValues.type,
                    isHasSubClasses: classValues.isHasSubClasses,
                    eliminationDraw: Number(classValues.eliminationDraw),
                    minPlayers: Number(classValues.minPlayers),
                    maxPlayers: Number(classValues.maxPlayers),
                    numberOfGroups: Number(classValues.numberOfGroups),
                    keywordSurface: classValues.keywordSurface,
                    keywordBall: classValues.keywordBall,
                    isOnSale: getBool(classValues.isOnSale),
                    isDupr: getBool(classValues.isDupr),
                    requiresLicense: getBool(classValues.requiresLicense),
                    isRanking: getBool(classValues.isRanking),
                    rankingType:
                        Number(classValues.rankingType) || SettingsRankingTypeEnum.RANKING_300p,
                    format: classValues.format,
                    roundScoring: getBool(classValues.roundScoring),
                    price: getBool(classValues.isOnSale) ? Number(classValues.price) : null,
                    vat: getBool(classValues.isOnSale) ? Number(classValues.vat) : null,
                    salesStatus: getBool(classValues.isOnSale)
                        ? getBool(classValues.salesStatus)
                        : false,
                    stateOfClass: getBool(classValues.stateOfClass),
                    teams: classValues.teams.map((team: any) => {
                        team.users = team.users.filter((v: any, index: number) => {
                            const maxLength =
                                classValues.type === ClassTypeEnum.SINGLES
                                    ? 1
                                    : classValues.type === ClassTypeEnum.DOUBLES
                                    ? 2
                                    : 999;
                            return index < maxLength;
                        });
                        if (typeof team.id === 'number') {
                            return { ...team, seed: Number(team.seed) };
                        } else {
                            const { id, ...rest } = team;
                            return { ...rest, seed: Number(rest.seed) };
                        }
                    }),
                    tournamentCategories: classValues.tournamentCategories.map((item) => {
                        return {
                            ...item,
                            id: typeof item.id === 'number' ? item.id : undefined,
                            isActive: getNumberBool(item.isActive),
                        } as TournamentCategory;
                    }),
                    tournamentMatches: classValues.tournamentMatches,
                };
            }

            if (isNewClass) {
                return {
                    ...rest,
                    eliminationDraw: Number(rest.eliminationDraw),
                    isHasSubClasses: rest.isHasSubClasses,
                    minPlayers: Number(rest.minPlayers),
                    maxPlayers: Number(rest.maxPlayers),
                    price: getBool(rest.isOnSale) ? Number(rest.price) : null,
                    vat: getBool(rest.isOnSale) ? Number(rest.vat) : null,
                    isOnSale: getBool(rest.isOnSale),
                    isRanking: getBool(rest.isRanking),
                    numberOfGroups: Number(rest.numberOfGroups),
                    rankingType:
                        Number(classValues.rankingType) || SettingsRankingTypeEnum.RANKING_300p,
                    roundScoring: getBool(rest.roundScoring),
                    salesStatus: getBool(rest.salesStatus),
                    isDupr: getBool(rest.isDupr),
                    requiresLicense: getBool(rest.requiresLicense),
                    stateOfClass: getBool(rest.stateOfClass),
                    teams:
                        rest.teams?.length &&
                        rest.teams.map((team: any) => {
                            team.users = team.users.filter((v: any, index: number) => {
                                const maxLength =
                                    classValues.type === ClassTypeEnum.SINGLES
                                        ? 1
                                        : classValues.type === ClassTypeEnum.DOUBLES
                                        ? 2
                                        : 999;
                                return index < maxLength;
                            });
                            if (typeof team.id === 'number') {
                                return { ...team, seed: Number(team.seed) };
                            } else {
                                const { id, ...rest } = team;
                                return { ...rest, seed: Number(rest.seed) };
                            }
                        }),
                };
            } else {
                return {
                    id: classId,
                    ...rest,
                    minPlayers: Number(rest.minPlayers),
                    maxPlayers: Number(rest.maxPlayers),
                    isHasSubClasses: rest.isHasSubClasses,
                    isRanking: getBool(rest.isRanking),
                    rankingType: Number(rest.rankingType) || SettingsRankingTypeEnum.RANKING_300p,
                    price: getBool(rest.isOnSale) ? Number(rest.price) : null,
                    vat: getBool(rest.isOnSale) ? Number(rest.vat) : null,
                    isOnSale: getBool(rest.isOnSale),
                    roundScoring: getBool(rest.roundScoring),
                    numberOfGroups: Number(rest.numberOfGroups),
                    salesStatus: getBool(rest.salesStatus),
                    isDupr: getBool(rest.isDupr),
                    requiresLicense: getBool(rest.requiresLicense),
                    stateOfClass: getBool(rest.stateOfClass),
                    teams: classValues.teams.map((team: any) => {
                        team.users = team.users.filter((v: any, index: number) => {
                            const maxLength =
                                classValues.type === ClassTypeEnum.SINGLES
                                    ? 1
                                    : classValues.type === ClassTypeEnum.DOUBLES
                                    ? 2
                                    : 999;
                            return index < maxLength;
                        });
                        if (typeof team.id === 'number') {
                            return { ...team, seed: Number(team.seed) };
                        } else {
                            const { id, ...rest } = team;
                            return { ...rest, seed: Number(rest.seed) };
                        }
                    }),
                };
            }
        }),
    };
};

export const singleClassTagTypeConverter = (
    classValues: ClassValues,
    classCollection: Array<ClassValues>
) => {
    const prevClass = classCollection.find(({ classId }) => classValues.classId === classId);
    const isNewClass = typeof classValues.classId === 'string';

    const { classId, ...rest } = classValues;

    if (classValues.teams.length) {
        classValues.teams = classValues.teams.map(({ shuffled, ...item }) => {
            return {
                ...item,
                id: typeof item.id === 'number' ? item.id : undefined,
                seed: Number(item.seed),
            } as ClassTeam;
        });
    }

    let teams = classValues.teams.map((team: any) => {
        team.users = team.users.filter((v: any, index: number) => {
            const maxLength =
                classValues.type === ClassTypeEnum.SINGLES
                    ? 1
                    : classValues.type === ClassTypeEnum.DOUBLES
                    ? 2
                    : 999;
            return index < maxLength;
        });

        if (typeof team.id === 'number') {
            return { ...team, seed: Number(team.seed) };
        } else {
            const { id, ...rest } = team;
            return { ...rest, seed: Number(rest.seed) };
        }
    });

    if (classValues.format === ClassFormatEnum.BOX_LEAGUE) {
        const newUsers = classValues.teams.map((team) => team.users[0].id);

        const prevSingleTeams = prevClass?.teams.filter((team) => team.users.length === 1) || [];
        // For the invalid double teams, we should check if any of the previousSingleTeam have changed user in the new teams.
        // Object that maps all the old user ids to the new user ids. So we can update any user according to the changes
        const userMap = prevSingleTeams.reduce((acc: Record<number, User>, team) => {
            const newTeam = classValues.teams.find((t) => t.id === team.id);
            acc[team.users[0].id] = newTeam?.users[0] ?? team.users[0];
            return acc;
        }, {});
        // For Box League we first need to compare the previous teams with the new ones, and if any of the users have changed, we need to change the users in the "hidden" double teams as well
        const doubleTeams = prevClass?.teams.filter((team) => team.users.length > 1) || [];

        const updatedDoubleTeams = doubleTeams.map((team) => {
            const player1 = userMap[team.users[0].id] ?? team.users[0];
            const player2 = userMap[team.users[1].id] ?? team.users[1];
            return {
                ...team,
                name: generateTeamName(
                    [player1, player2],
                    { value: classValues.type, label: '' },
                    team
                ),
                users: [player1, player2],
            };
        });

        const validDoubleTeams = updatedDoubleTeams?.filter((team) =>
            team.users.every((user) => newUsers.includes(user.id))
        );

        teams = teams.concat(validDoubleTeams);
    }

    if (!isNewClass) {
        let vat: number | null = Number(classValues.vat);
        if (!getBool(classValues.useVat)) {
            vat = 0;
        }
        if (!getBool(classValues.isOnSale)) {
            vat = null;
        }
        // const vat = getBool(classValues.isOnSale) ? getBool(classValues.useVat) ? Number(classValues.vat) : null;
        return {
            ...prevClass,
            ...rest,
            id: classId,
            name: classValues.name,
            type: classValues.type,
            isHasSubClasses: classValues.isHasSubClasses,
            eliminationDraw: Number(classValues.eliminationDraw),
            minPlayers: Number(classValues.minPlayers),
            maxPlayers: Number(classValues.maxPlayers),
            keywordSurface: classValues.keywordSurface,
            keywordBall: classValues.keywordBall,
            isOnSale: getBool(classValues.isOnSale),
            isRanking: getBool(classValues.isRanking),
            rankingType: Number(classValues.rankingType) || SettingsRankingTypeEnum.RANKING_300p,
            numberOfGroups: Number(classValues.numberOfGroups),
            format: classValues.format,
            roundScoring: getBool(classValues.roundScoring),
            price: getBool(classValues.isOnSale) ? Number(classValues.price) : null,
            vat: vat,
            salesStatus: getBool(classValues.isOnSale) ? getBool(classValues.salesStatus) : false,
            isDupr: getBool(classValues.isDupr) ? getBool(classValues.isDupr) : false,
            requiresLicense: getBool(classValues.requiresLicense)
                ? getBool(classValues.requiresLicense)
                : false,
            stateOfClass: getBool(classValues.stateOfClass),
            teams: teams,
            tournamentCategories: classValues.tournamentCategories.map((item) => {
                return {
                    ...item,
                    id: typeof item.id === 'number' ? item.id : undefined,
                    isActive: getNumberBool(item.isActive),
                } as TournamentCategory;
            }),
            tournamentMatches: classValues.tournamentMatches,
        };
    } else {
        return {
            ...prevClass,
            ...rest,
            name: classValues.name,
            type: classValues.type,
            isHasSubClasses: classValues.isHasSubClasses,
            eliminationDraw: Number(classValues.eliminationDraw),
            minPlayers: Number(classValues.minPlayers),
            maxPlayers: Number(classValues.maxPlayers),
            numberOfGroups: Number(classValues.numberOfGroups),
            keywordSurface: classValues.keywordSurface,
            keywordBall: classValues.keywordBall,
            isOnSale: getBool(classValues.isOnSale),
            isRanking: getBool(classValues.isRanking),
            rankingType: Number(classValues.rankingType) || SettingsRankingTypeEnum.RANKING_300p,
            format: classValues.format,
            roundScoring: getBool(classValues.roundScoring),
            price: getBool(classValues.isOnSale) ? Number(classValues.price) : null,
            vat: getBool(classValues.isOnSale) ? Number(classValues.vat) : null,
            salesStatus: getBool(classValues.isOnSale) ? getBool(classValues.salesStatus) : false,
            isDupr: getBool(classValues.isDupr) ? getBool(classValues.isDupr) : false,
            requiresLicense: getBool(classValues.requiresLicense)
                ? getBool(classValues.requiresLicense)
                : false,
            stateOfClass: getBool(classValues.stateOfClass),
            teams: classValues.teams.map((team: any) => {
                team.users = team.users.filter((v: any, index: number) => {
                    const maxLength =
                        classValues.type === ClassTypeEnum.SINGLES
                            ? 1
                            : classValues.type === ClassTypeEnum.DOUBLES
                            ? 2
                            : 999;
                    return index < maxLength;
                });
                if (typeof team.id === 'number') {
                    return { ...team, seed: Number(team.seed) };
                } else {
                    const { id, ...rest } = team;
                    return { ...rest, seed: Number(rest.seed) };
                }
            }),
            tournamentCategories: classValues.tournamentCategories.map((item) => {
                return {
                    ...item,
                    id: typeof item.id === 'number' ? item.id : undefined,
                    isActive: getNumberBool(item.isActive),
                } as TournamentCategory;
            }),
            tournamentMatches: classValues.tournamentMatches,
        };
    }
};
