import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBox } from './ModalBox';

interface ConfirmModalProps {
    children?: JSX.Element;
    opened?: boolean;
    onHide?: () => void;
    onConfirm: () => void;
    text?: string;
    description?: string;
    disabled?: boolean;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
    onConfirm,
    children,
    opened,
    onHide,
    text,
    description,
    disabled,
}) => {
    const prefix = 'modal-';
    const btnPrefix = 'button-';

    const [isOpen, setIsOpen] = useState(opened || false);
    const { t } = useTranslation();

    const openModal = () => !disabled && setIsOpen(true);

    const closeModal = () => {
        onHide && onHide();
        setIsOpen(false);
    };

    const confirm = () => {
        onConfirm();
        closeModal();
    };

    useEffect(() => {
        setIsOpen(!!opened);
    }, [opened]);

    return (
        <>
            {children && <div onClick={openModal}>{children}</div>}

            <ModalBox
                show={isOpen}
                handleSave={confirm}
                size="sm"
                onHide={closeModal}
                btnText={t(btnPrefix + 'confirm')}
                withoutMinHeight={true}
            >
                <div className="d-flex flex-column gap-3">
                    <h5>{text || t(prefix + 'confirmBody')}</h5>
                    {description && <p>{description}</p>}
                </div>
            </ModalBox>
        </>
    );
};
