export enum SettingsSportEnum {
    PICKLEBALL = 1,
}

export enum SettingsTourEnum {
    NONE = 0,
    STANDALONE = 4,
}

export enum SettingsTournamentTypeEnum {
    REGULAR = 1,
    TEAM = 2,
}

export enum SettingsStatusEnum {
    DRAFT = 0,
    PUBLISHED = 1,
    STARTED = 2,
    ENDED = 3,
}

export enum SettingsRatingTypeEnum {
    RATING_100 = 100,
    RATING_80 = 80,
    RATING_60 = 60,
}

export enum SettingsRankingTypeEnum {
    RANKING_300p = 300,
    RANKING_200p = 200,
    RANKING_100p = 100,
}

export enum ClassSettingsBallEnum {
    FRANKLIN_X40 = 1,
    FRANKLIN_X26 = 2,
    DURA_FAST_40 = 3,
    ONYX = 4,
    EXEL = 5,
    ZCEBRA = 6,
}

export enum ClassSettingsSurfaceEnum {
    HARD_COURT = 1,
    PLASTIC_SPORT_FLOOR = 2,
    WOOD = 3,
    TARMAC = 4,
    CONCRETE = 5,
}

export enum ClassTypeEnum {
    SINGLES = 1,
    DOUBLES = 2,
    CUP = 3,
    TEAMS = 4,
}

export enum ClassFormatEnum {
    GROUP = 1,
    BRACKET = 2,
    SINGLE_BRACKET = 3,
    GROUP_BRACKET = 4,
    BOX_LEAGUE = 5,
}

export enum EliminationDrawEnum {
    TOP_1 = 1,
    TOP_2 = 2,
    TOP_3 = 3,
    TOP_4 = 4,
    ALL = 0,
}

export enum TournamentMatchStatusEnum {
    NOT_STARTED = 0,
    STARTED = 1,
    FINISHED = 2,
}

export enum ClassMatchType {
    ROUND1 = 1,
    ROUND2 = 2,
    ROUND3 = 3,
    ROUND4 = 4,
    ROUND5 = 5,
    ROUND6 = 6,
    ROUND7 = 7,
    ROUND8 = 8,
    ROUND9 = 9,
    ROUND10 = 10,
    QUARTERFINAL = 11,
    SEMIFINAL = 12,
    FINAL = 13,
}

export enum MatchBracketType {
    NO_BRACKET = 0,
    WINNERS = 1,
    LOSERS = 2,
}

export enum CreativeOptions {
    PUBLIC_EVENT = 1,
    TOURNAMENT = 2,
    LEAGUE = 3,
}

export enum TypeError {
    info = 'info',
    success = 'success',
    warning = 'warning',
    error = 'error',
}

export enum BracketOptionsEnum {
    None,
    Winner,
    Consolation,
}

export enum ScheduleFilterType {
    PLAYER = 'player',
    CLASS = 'class',
    TEAM = 'team',
    TIME = 'time',
}

export const BracketOptions = [
    { value: BracketOptionsEnum.None, label: 'enum-bracketOptionsNone' },
    { value: BracketOptionsEnum.Winner, label: 'enum-bracketOptionsWinner' },
    { value: BracketOptionsEnum.Consolation, label: 'enum-bracketOptionsConsolation' },
];

export const TournamentMatchStatusEnumTranslation = {
    [TournamentMatchStatusEnum.NOT_STARTED]: 'enum-notStarted',
    [TournamentMatchStatusEnum.STARTED]: 'enum-started',
    [TournamentMatchStatusEnum.FINISHED]: 'enum-finished',
};

export const ClassGroupDraw = [
    { value: EliminationDrawEnum.TOP_1, label: 'enum-groupTop', props: { num: 1 } },
    { value: EliminationDrawEnum.TOP_2, label: 'enum-groupTop', props: { num: 2 } },
    { value: EliminationDrawEnum.TOP_3, label: 'enum-groupTop', props: { num: 3 } },
    { value: EliminationDrawEnum.TOP_4, label: 'enum-groupTop', props: { num: 4 } },
    { value: EliminationDrawEnum.ALL, label: 'enum-groupTopAll', props: {} },
];

export enum TeamTournamentType {
    Regular = 0,
    Team = 1,
}
