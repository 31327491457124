import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { ReactSelectForm, CustomButton } from '../../../../../components';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { GroupSection } from '../GroupSection/GroupSection';
import { MatchSection } from '../MatchSection';
import { Group, MatchResult, Team, User, TournamentGroup } from '../../../../../types/tournament';
import { playTagTypeConverter } from '../../../../../utils/playTagTypeConverter';
import { updatePlay } from '../../../../../features/tournament/actionCreators';
import { ClassFormatEnum } from '../../../../../types/enums';
import { getStringDateFormat } from '../../../../../utils/getStringDate';
import { selectClass } from '../../../../../features/class/classSelectors';
import { TagIdsEnum } from '../../../../../constant/navigation';
import { createAndSortOptionsForClassSelect } from '../../../CreativeTourDetail.helpers';
import { useEffect, useState } from 'react';
import { fetchClassesByTournamentIdAndClassId } from '../../../../../apis/tournament';
import { selectTournamentData } from '../../../../../features/tournament/tournamentSelectors';

import './PlayTag.scss';
import { classSetSingleClass } from '../../../../../features/class/classSlice';

export interface IMatch {
    readonly id: string | number;
    name: string;
    type: number;
    startDate: string;
    matchId: string;
    status?: number; // should not send to backend (separate route for update)
    homeTeam: Team | null;
    awayTeam: Team | null;
    homePlayer1: User | null;
    homePlayer2: User | null;
    awayPlayer1: User | null;
    awayPlayer2: User | null;
    homeTeamPlaceholder: string | null;
    awayTeamPlaceholder: string | null;
    court: number | null;
    results?: Array<MatchResult>;
    tournamentGroup?: TournamentGroup;
    tournamentGroupId: number | null;
    isGold: string;
    bracket: number;
    sortKey: number | null;
}

export interface PlayValues {
    readonly classId: string | number;
    readonly type?: number;
    tournamentGroups: Array<Group>;
    tournamentMatches: { [key in number]: IMatch };
}

export const PlayTag = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const buttonPrefix = 'button-';
    const { id: tournamentId } = useAppSelector(selectTournamentData) || { id: null };

    const { currentClassId, classCollection } = useAppSelector(selectClass);

    const [currentClass, setCurrentClass] = useState<any>(null as unknown as any);
    const [loading, setLoading] = useState<boolean>(false);
    const { data } = useAppSelector((state) => state.tournament);

    const courts = data?.courts;
    const initialFormikData = {
        classId: currentClassId,
        type: currentClass ? currentClass.type : null,
        tournamentGroups: currentClass ? currentClass.tournamentGroups : [],
        tournamentMatches: currentClass
            ? currentClass.tournamentMatches?.reduce((acc: any, item: any) => {
                  const newItem: IMatch = { ...item };
                  newItem.startDate = getStringDateFormat(item.startDate || '');
                  newItem.court = (item.court as any)?.id || item.court;
                  acc[`${item.id}`] = newItem;
                  return acc;
              }, {})
            : {},
    } as PlayValues;
    const playOptions = createAndSortOptionsForClassSelect(classCollection);

    const prefix = 'creativeDetail-';

    const isGenerateButton = !Object.values(initialFormikData.tournamentMatches).find(
        ({ tournamentGroupId }) => {
            return typeof tournamentGroupId !== 'number';
        }
    );

    const getClassData = async () => {
        if (typeof tournamentId === 'number' && typeof currentClassId === 'number') {
            try {
                setLoading(true);
                const data = await fetchClassesByTournamentIdAndClassId(currentClassId);
                dispatch(classSetSingleClass(data));
                setCurrentClass(data);
                setLoading(false);
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        getClassData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId, currentClassId]);

    return (
        <div className="px-2">
            {classCollection.length ? (
                <div>
                    <Formik
                        onSubmit={console.log}
                        initialValues={{ classId: currentClassId || null }}
                    >
                        <form>
                            <div className="d-flex align-items-center my-3">
                                <h4 className="font-weight-bold mb-0">
                                    {t(prefix + 'classesTitle')}
                                </h4>
                            </div>
                            <div className="raqt__select-wrapper">
                                {loading && <div className="disabled-block"></div>}
                                <ReactSelectForm
                                    options={playOptions}
                                    name="classId"
                                    label={t(prefix + 'className')}
                                />
                            </div>
                        </form>
                    </Formik>
                    {(!loading && (
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialFormikData}
                            validateOnChange={false}
                            validateOnBlur={true}
                            validateOnMount={false}
                            onSubmit={(values: PlayValues) => {
                                typeof currentClassId === 'number' &&
                                    dispatch(
                                        updatePlay(currentClassId, playTagTypeConverter(values))
                                    );
                            }}
                        >
                            {(formikArg) => (
                                <Form autoComplete="off" id={TagIdsEnum.PLAY}>
                                    {(currentClass?.format === ClassFormatEnum.GROUP ||
                                        currentClass?.format === ClassFormatEnum.GROUP_BRACKET ||
                                        currentClass?.format === ClassFormatEnum.BOX_LEAGUE) &&
                                        !currentClass.parentClassId && (
                                            <GroupSection
                                                formikArg={formikArg}
                                                currentClass={currentClass}
                                                teams={currentClass.teams}
                                            />
                                        )}
                                    <div className="d-flex justify-content-start align-items-center pt-5">
                                        <h4 className="font-weight-bold mb-0">
                                            {t(prefix + 'matches')}
                                        </h4>
                                        {isGenerateButton &&
                                            ((currentClass?.format !== ClassFormatEnum.GROUP &&
                                                currentClass?.format !==
                                                    ClassFormatEnum.GROUP_BRACKET &&
                                                currentClass?.format !==
                                                    ClassFormatEnum.BOX_LEAGUE) ||
                                                formikArg.values.tournamentGroups.length > 0) &&
                                            Object.values(formikArg.values.tournamentMatches)
                                                .length === 0 && (
                                                <CustomButton
                                                    variant="outline-dark"
                                                    btnText={t(buttonPrefix + 'generate')}
                                                    className="d-flex justify-content-between align-items-center ms-3"
                                                    cb={() => {
                                                        typeof currentClassId === 'number' &&
                                                            dispatch(
                                                                updatePlay(currentClassId, [], true)
                                                            );
                                                    }}
                                                >
                                                    <AddIcon />
                                                </CustomButton>
                                            )}
                                    </div>
                                    <MatchSection formikArg={formikArg} courts={courts} />
                                </Form>
                            )}
                        </Formik>
                    )) || <div>Loading...</div>}
                </div>
            ) : (
                <h4 className="font-weight-bold no-classes">{t(prefix + 'warning2')}</h4>
            )}
        </div>
    );
};
