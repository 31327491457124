import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CloseCircle } from '../../assets/icons/CloseCircle';

import { User } from '../../types/tournament';
import { RoundedAvatar } from '..';
import { TicketIcon } from '../../assets/icons/TicketIcon';
import { Ticket } from '../../types/Ticket';
import { TicketOffIcon } from '../../assets/icons/TicketOffIcon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

export type TicketDisplayStatus = 'pending' | 'missing' | 'notUsed' | 'active';
interface MemberTournamentCardProps {
    onClick?: (arg: number) => void;
    member: User;
    ticket?: Ticket;
    ticketStatus?: TicketDisplayStatus;
}

export const MemberTournamentCard: FC<MemberTournamentCardProps> = ({
    onClick,
    member,
    ticket,
    ticketStatus = 'notUsed',
}) => {
    const { userName, id, avatar, firstName, lastName } = member;
    const { t } = useTranslation();
    const fullName = firstName && lastName ? firstName + ' ' + lastName : userName;
    const [copied, setCopied] = useState(false);
    const targetRef = useRef(null);
    const prefix = 'creativeDetail-';

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 3000);
        }
    }, [copied]);

    const ticketData = useMemo(() => {
        switch (ticketStatus) {
            case 'pending':
                return {
                    icon: <TicketIcon className="text-warning" />,
                    tooltip: t(prefix + 'ticketPending'),
                };
            case 'missing':
                return {
                    icon: <TicketOffIcon className="text-danger" />,
                    tooltip: t(prefix + 'ticketMissing'),
                };
            case 'active': {
                if (ticket?.paymentId.startsWith('pi_')) {
                    return {
                        icon: (
                            <CopyToClipboard text={ticket.paymentId} onCopy={() => setCopied(true)}>
                                <button type="button" className="border-0 bg-transparent">
                                    <TicketIcon className="text-success" />
                                </button>
                            </CopyToClipboard>
                        ),
                        tooltip: ticket.paymentId,
                    };
                }
                return {
                    icon: <TicketIcon className="text-success" />,
                    tooltip: ticket?.paymentId,
                };
            }
            case 'notUsed':
            default:
                return {
                    icon: null,
                    tooltip: null,
                };
        }
    }, [ticketStatus, ticket, t]);

    return (
        <div
            style={{ minHeight: '43px' }}
            className="d-flex justify-content-lg-center justify-content-between align-items-center mr-2 border bg-white my-2 br-16"
        >
            <RoundedAvatar avatar={avatar} alt="User avatar" name="user" />
            <div className="d-flex flex-grow-1">
                <span className="px-2">{fullName}</span>
            </div>
            <OverlayTrigger placement="top" overlay={<Tooltip>{ticketData.tooltip}</Tooltip>}>
                <div ref={targetRef}>{ticketData.icon}</div>
            </OverlayTrigger>
            <Overlay placement="bottom" show={copied} target={targetRef.current}>
                <Tooltip>{t(prefix + 'ticketCopied')}</Tooltip>
            </Overlay>
            {onClick ? (
                <Button
                    className="border-0 btnDeleteMember"
                    variant="outline-dark"
                    onClick={() => onClick(id)}
                >
                    <CloseCircle />
                </Button>
            ) : null}
        </div>
    );
};
