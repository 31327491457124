import React from 'react';
import { SvgIcon } from '../../components';

export const TicketOffIcon = ({
    color = 'currentColor',
    className,
}: {
    color?: string;
    className?: string;
}) => {
    return (
        <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            className={className}
        >
            <path d="M15 5v2"></path>
            <path d="M15 17v2"></path>
            <path d="M9 5h10a2 2 0 0 1 2 2v3a2 2 0 1 0 0 4v3m-2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 1 0 0 -4v-3a2 2 0 0 1 2 -2"></path>
            <path d="M3 3l18 18"></path>
        </SvgIcon>
    );
};
