import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
    ButtonFormikTab,
    CustomButton,
    FormTextField,
    ReactSelectForm,
} from '../../../../../components';

import { SettingBox } from '../../../common/components/SettingBox';
import {
    boolOption,
    initialData,
    isDiscountOption,
    ratingOption,
    stateClassOption,
    ticketSalesOption,
    tripleOption,
} from '../../../../../constant/setting';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { openModal } from '../../../../../features/modal/modalSlice';
import { ModalAction, ModalName } from '../../../../../types/modal';
import { deleteMember } from '../../../../../features/setting/settingSlice';
import { settingTagTypeConverter } from '../../../../../utils/settingTagTypeConverter';
import { updateTournamentInfo } from '../../../../../features/tournament/actionCreators';
import { MemberTournamentCard } from '../../../../../components';
import { TagIdsEnum } from '../../../../../constant/navigation';
import { keywordsTypes } from '../../../../adminConfiguration/adminCounfiguration';

import './SettingTag.scss';
import { useParams } from 'react-router-dom';
import { getBool } from '../../../../../utils/typeConverters';

export interface ISettingValues {
    keywordSport: number;
    tour: string;
    keywordTournamentType: number;
    status: string;
    showOnPublicPage: string;
    liveStreaming: string;
    liveStreamingLink: string;
    isRating: string;
    ratingType: string;
    isRanking: string;
    rankingType: string;
    isCanRecordScores: string;
    // matchPoints: number;
    stateOfClass: string;
    keywordBall: number;
    keywordSurface: number;
    isTicketSales: string;
    isShowSalesButton: string;
    minPlayers: number;
    maxPlayers: number;
    price: number;
    vat: number;
    useVat: boolean;
    isDiscount: boolean | string;
    discount1: number;
    discount2: number;
    discount3: number;
    isDupr: boolean;
    requiresLicense: boolean;
}

export type SettingValues = ISettingValues;

export const SettingTag = () => {
    const { t } = useTranslation();
    const prefix = 'creativeDetail-';
    const buttonPrefix = 'button-';
    const prefixValidation = 'validation-';

    const dispatch = useAppDispatch();
    const { data } = useAppSelector((state) => state.tournament);
    const { listTour, listKeywords } = useAppSelector((state) => state.settings);

    const officials = useAppSelector((state) => state.settings.officialsMembers);
    const { communityId } = useParams<{ communityId: string }>();

    const sportOptions = listKeywords
        .filter(({ type }) => type === keywordsTypes.SPORT)
        .map(({ id, name }) => ({ value: id, label: name }));
    const tournamentTypeOptions = listKeywords
        .filter(({ type }) => type === keywordsTypes.TOURNAMENT_TYPE)
        .map(({ id, name }) => ({ value: id, label: name }));
    const surfaceOption = listKeywords
        .filter(({ type }) => type === keywordsTypes.SURFACE)
        .map(({ id, name }) => ({ value: id, label: name }));
    const ballOption = listKeywords
        .filter(({ type }) => type === keywordsTypes.BALL)
        .map(({ id, name }) => ({ value: id, label: name }));

    const formikInitialState =
        data?.settings && data?.classSettings
            ? ({
                  keywordSport: data.settings.keywordSport,
                  tour: data.settings.tour.toString(),
                  keywordTournamentType: data.settings.keywordTournamentType,
                  status: t(tripleOption[data.settings.status]),
                  showOnPublicPage: data.settings.showOnPublicPage.toString(),
                  liveStreaming: data.settings.liveStreaming.toString(),
                  liveStreamingLink: data.settings.liveStreamingLink,
                  isRating: data.settings.isRating.toString(),
                  ratingType: data.settings.ratingType.toString(),
                  isCanRecordScores: data.settings.isCanRecordScores.toString(),
                  // matchPoints: data.settings.matchPoints,
                  stateOfClass: t(stateClassOption[Number(data.classSettings.stateOfClass)]),
                  keywordBall: data.classSettings.keywordBall,
                  keywordSurface: data.classSettings.keywordSurface,
                  isTicketSales: t(ticketSalesOption[Number(data.classSettings.isTicketSales)]),
                  isShowSalesButton: data.classSettings.isShowSalesButton.toString(),
                  minPlayers: data.classSettings.minPlayers,
                  maxPlayers: data.classSettings.maxPlayers,
                  price: data.classSettings.price,
                  vat: data.classSettings.vat,
                  useVat: data.classSettings.vat !== 0,
                  isDiscount: data.isDiscount.toString(),
                  discount1: data.discount1,
                  discount2: data.discount2,
                  discount3: data.discount3,
                  isDupr: data.classSettings.isDupr || false,
                  requiresLicense: data.classSettings.requiresLicense || false,
              } as ISettingValues)
            : { ...initialData(t) };

    const SettingSchema = Yup.object().shape({
        liveStreaming: Yup.boolean(),
        liveStreamingLink: Yup.string().when('liveStreaming', {
            is: true,
            then: Yup.string()
                .required(t(prefixValidation + 'required'))
                .url(t(prefixValidation + 'url')),
        }),
        // matchPoints: Yup.number()
        //     .when("keywordTournamentType", {
        //         is: (tournamentType: number) => tournamentType === 14,
        //         then: Yup.number().required(t(prefixValidation + 'required'))
        //         .typeError(t(prefixValidation + 'mustBeANumber'))
        //         .positive(t(prefixValidation + 'positive')),
        //     }),
        minPlayers: Yup.number().when('keywordTournamentType', {
            is: (tournamentType: number) => tournamentType !== 14,
            then: Yup.number()
                .required(t(prefixValidation + 'required'))
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .positive(t(prefixValidation + 'positive')),
        }),
        maxPlayers: Yup.number().when('keywordTournamentType', {
            is: (tournamentType: number) => tournamentType !== 14,
            then: Yup.number()
                .required(t(prefixValidation + 'required'))
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .positive(t(prefixValidation + 'positive')),
        }),
        price: Yup.number()
            .typeError(t(prefixValidation + 'mustBeANumber'))
            .required(t(prefixValidation + 'required'))
            .positive(t(prefixValidation + 'positive')),
        vat: Yup.number()
            .typeError(t(prefixValidation + 'mustBeANumber'))
            .required(t(prefixValidation + 'required'))
            .min(0, t(prefixValidation + 'positive')),
        discount1: Yup.number().when('isDiscount', {
            is: 'true',
            then: Yup.number()
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .required(t(prefixValidation + 'required'))
                .moreThan(-1, t(prefixValidation + 'positive'))
                .lessThan(100)
                .nullable(true),
        }),
        discount2: Yup.number().when('isDiscount', {
            is: 'true',
            then: Yup.number()
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .required(t(prefixValidation + 'required'))
                .moreThan(-1, t(prefixValidation + 'positive'))
                .lessThan(100)
                .nullable(true),
        }),
        discount3: Yup.number().when('isDiscount', {
            is: 'true',
            then: Yup.number()
                .typeError(t(prefixValidation + 'mustBeANumber'))
                .required(t(prefixValidation + 'required'))
                .moreThan(-1, t(prefixValidation + 'positive'))
                .lessThan(100)
                .nullable(true),
        }),
    });

    const handleDeleteMember = useCallback(
        (id: number) => {
            dispatch(deleteMember(id));
        },
        [dispatch]
    );

    const handleOpenModal = useCallback(() => {
        dispatch(
            openModal({
                currentModal: ModalAction.ADD_MEMBER,
                modalOpen: true,
                data: {
                    title: 'Add tournament officials: ',
                    modalName: ModalName.ADD_TOURNAMENT_MEMBER,
                    selected: officials,
                },
            })
        );
    }, [dispatch, officials]);

    return (
        <div className="px-2">
            <Formik
                enableReinitialize={true}
                validationSchema={SettingSchema}
                initialValues={formikInitialState}
                onSubmit={(values: ISettingValues) => {
                    data &&
                        dispatch(
                            updateTournamentInfo(data.id, +communityId, {
                                ...settingTagTypeConverter(values, t),
                                officials,
                            })
                        );
                }}
            >
                {({ values }) => (
                    <Form autoComplete="off" id={TagIdsEnum.SETTINGS}>
                        <div className="row">
                            <SettingBox title="Sport">
                                <ReactSelectForm
                                    labelPdd="pb-0"
                                    options={sportOptions}
                                    name="keywordSport"
                                />
                            </SettingBox>
                            <SettingBox title="Tour">
                                <ReactSelectForm
                                    labelPdd="pb-0"
                                    options={listTour.map((tour) => {
                                        return { value: tour.id.toString(), label: tour.name };
                                    })}
                                    name="tour"
                                />
                            </SettingBox>
                            <SettingBox title="TournamentType">
                                <ReactSelectForm
                                    labelPdd="pb-0"
                                    options={tournamentTypeOptions}
                                    name="keywordTournamentType"
                                />
                            </SettingBox>
                            <SettingBox title="Status">
                                <ButtonFormikTab
                                    currValue={t(values.status)}
                                    items={tripleOption.map(t) as string[]}
                                    name="status"
                                />
                            </SettingBox>
                            <SettingBox title="Discount">
                                <ButtonFormikTab
                                    currValue={values.isDiscount}
                                    items={isDiscountOption.map((i) => ({
                                        label: t(i.label),
                                        value: i.value,
                                    }))}
                                    name="isDiscount"
                                />
                            </SettingBox>

                            {values.isDiscount.toString() === 'true' && (
                                <SettingBox title="dpercent">
                                    <div className="setting-tag__discount-box">
                                        <div className="setting-tag__discount-input-box">
                                            <span>{t('creativeDetail-discount1')}</span>
                                            <FormTextField
                                                name="discount1"
                                                id="discount1"
                                                className="py-0"
                                            />
                                        </div>
                                        <div className="setting-tag__discount-input-box">
                                            <span>{t('creativeDetail-discount2')}</span>
                                            <FormTextField
                                                name="discount2"
                                                id="discount2"
                                                className="py-0"
                                            />
                                        </div>
                                        <div className="setting-tag__discount-input-box">
                                            <span>{t('creativeDetail-discount3')}</span>
                                            <FormTextField
                                                name="discount3"
                                                id="discount3"
                                                className="py-0"
                                            />
                                        </div>
                                    </div>
                                </SettingBox>
                            )}
                            <SettingBox title="Show">
                                <ButtonFormikTab
                                    currValue={values.showOnPublicPage}
                                    items={boolOption}
                                    name="showOnPublicPage"
                                />
                            </SettingBox>
                            <SettingBox title="Streaming">
                                <ButtonFormikTab
                                    currValue={values.liveStreaming}
                                    items={boolOption}
                                    name="liveStreaming"
                                />
                            </SettingBox>
                            {values.liveStreaming === 'true' && (
                                <SettingBox title="Link">
                                    <FormTextField
                                        name="liveStreamingLink"
                                        id="liveStreamingLink"
                                        className="py-0"
                                    />
                                </SettingBox>
                            )}
                            <SettingBox title="Rating">
                                <ButtonFormikTab
                                    currValue={values.isRating}
                                    items={boolOption}
                                    name="isRating"
                                />
                            </SettingBox>
                            <SettingBox title="ratingType">
                                <ButtonFormikTab
                                    currValue={values.ratingType}
                                    items={ratingOption}
                                    name="ratingType"
                                />
                            </SettingBox>
                            <SettingBox title="Record">
                                <ButtonFormikTab
                                    currValue={values.isCanRecordScores}
                                    items={boolOption}
                                    name="isCanRecordScores"
                                />
                            </SettingBox>
                            {/* {values.keywordTournamentType === 14 && (
                                <SettingBox title="matchPoints">
                                    <FormTextField
                                        name="matchPoints"
                                        id="matchPoints"
                                        className="py-0"
                                    />
                                </SettingBox>
                            )} */}
                            <div className="d-block d-lg-flex align-items-center my-3">
                                <div className="col-lg-4 col pb-0 pb-lg-3 pb-lg-0 ps-0">
                                    <span>{t(prefix + 'officials')}</span>
                                </div>
                                <div className="col px-0">
                                    <div className="w-100 d-flex flex-wrap flex-column flex-lg-row">
                                        <div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-start">
                                            <CustomButton
                                                className="d-flex justify-content-evenly align-items-center mr-0 mr-lg-2"
                                                variant="outline-dark"
                                                btnText={t(buttonPrefix + 'addOfficials')}
                                                cb={handleOpenModal}
                                            >
                                                <AddIcon />
                                            </CustomButton>
                                        </div>
                                        {officials.length ? (
                                            officials.map((member) => (
                                                <React.Fragment key={member.id}>
                                                    <MemberTournamentCard
                                                        member={member}
                                                        onClick={handleDeleteMember}
                                                    />
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <div className="d-flex flex-column justify-content-center fw-lighter">
                                                <p className="mb-0">
                                                    {t(prefix + 'noOfficials') + '...'}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            <h4 className="font-weight-bold">{t(prefix + 'default')}</h4>
                        </div>
                        <div className="row mb-5">
                            <SettingBox title="State">
                                <ButtonFormikTab
                                    currValue={t(values.stateOfClass)}
                                    items={stateClassOption.map(t) as string[]}
                                    name="stateOfClass"
                                />
                            </SettingBox>
                            <SettingBox title="Ball">
                                <ReactSelectForm
                                    labelPdd="pb-0"
                                    options={ballOption}
                                    name="keywordBall"
                                />
                            </SettingBox>
                            <SettingBox title="Surface">
                                <ReactSelectForm
                                    labelPdd="pb-0"
                                    options={surfaceOption}
                                    name="keywordSurface"
                                />
                            </SettingBox>
                            <SettingBox title="Ticket">
                                <ButtonFormikTab
                                    currValue={t(values.isTicketSales)}
                                    items={ticketSalesOption.map(t) as string[]}
                                    name="isTicketSales"
                                />
                            </SettingBox>
                            <SettingBox title="ShowBtn">
                                <ButtonFormikTab
                                    currValue={values.isShowSalesButton}
                                    items={boolOption}
                                    name="isShowSalesButton"
                                />
                            </SettingBox>

                            {values.keywordTournamentType !== 14 && (
                                <>
                                    <SettingBox title="MinPlayers">
                                        <div className="w-25">
                                            <FormTextField
                                                name="minPlayers"
                                                id="minPlayers"
                                                className="py-0"
                                            />
                                        </div>
                                    </SettingBox>
                                    <SettingBox title="MaxPlayers">
                                        <div className="w-25">
                                            <FormTextField
                                                name="maxPlayers"
                                                id="maxPlayers"
                                                className="py-0"
                                            />
                                        </div>
                                    </SettingBox>
                                </>
                            )}

                            <SettingBox title="UseVAT">
                                <ButtonFormikTab
                                    currValue={values.useVat}
                                    items={boolOption}
                                    name="useVat"
                                />
                            </SettingBox>
                            {getBool(values.useVat) && (
                                <SettingBox title="VATpercentage">
                                    <div className="w-25">
                                        <FormTextField name="vat" id="vat" className="py-0" />
                                    </div>
                                </SettingBox>
                            )}
                            <SettingBox title="VAT">
                                <div className="w-25">
                                    <FormTextField
                                        placeholder="€0"
                                        name="price"
                                        id="price"
                                        className="py-0"
                                    />
                                </div>
                            </SettingBox>
                            <SettingBox title="isDupr">
                                <ButtonFormikTab
                                    currValue={values.isDupr}
                                    items={boolOption}
                                    name="isDupr"
                                />
                            </SettingBox>
                            <SettingBox title="requiresLicense">
                                <ButtonFormikTab
                                    currValue={values.requiresLicense}
                                    items={boolOption}
                                    name="requiresLicense"
                                />
                            </SettingBox>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
