import React, { useCallback, useMemo } from 'react';
import { ModalAction } from '../../types/modal';
import { MemberModal } from '../modal/MemberModal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { closeModal } from '../../features/modal/modalSlice';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ClassModal } from '../../pages/tourDetails/TournamentClasses/components/ClassModal';
import { GroupsModal } from '../../pages/tourDetails/TournamentClasses/components/GroupsModal';
import { MatchTeamModal } from '../../pages/tourDetails/TournamentPlay/components/MatchTeamModal';
import { MatchPlayerModal } from '../../pages/tourDetails/TournamentPlay/components/MatchPlayerModal';
import { ResultModal } from '../../pages/tourDetails/TournamentPlay/components/ResultModal';
import { DialogModal } from './DialogModal';
import { KeywordsModal } from '../../pages/adminConfiguration/components/KeywordsModal';
import { SponsorModal } from '../../pages/adminConfiguration/components/SponsorModal';
import { PaddleModal } from '../../pages/adminConfiguration/components/PaddleModal';
import { TourModal } from '../../pages/adminConfiguration/components/TourModal';
import { TeamModal } from '../../pages/tourDetails/TournamentPlay/components/TeamModal';
import { TransferTeamModal } from '../../pages/tourDetails/TournamentClasses/components/TransferTeamModal/TransferTeamModal';
import { ChangeClassTypeModal } from '../../pages/tourDetails/TournamentClasses/components/ChangeClassTypeModal/ChangeClassTypeModal';

export const Modal = () => {
    const { currentModal, modalOpen } = useAppSelector((state) => state.modals);
    const dispatch = useAppDispatch();
    const [width] = useWindowSize();
    const size = width > 1100 ? 'lg' : 'sm';

    const handleCloseModal = useCallback(() => {
        dispatch(closeModal());
    }, [dispatch]);

    const renderModal = useMemo((): JSX.Element | null => {
        switch (currentModal) {
            case ModalAction.ADD_MEMBER:
                return <MemberModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.ADD_CAPTAIN:
                return <MemberModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.ADD_TEAM:
                return <TeamModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.ADD_TOURNAMENT_CLASS:
                return <ClassModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.ADD_GROUPS_PLAY:
                return <GroupsModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.ADD_MATCH_TEAM:
                return <MatchTeamModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.ADD_MATCH_PLAYER:
                return <MatchPlayerModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.EDIT_MATCH_RESULT:
                return <ResultModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.DIALOG_WINDOW:
                return <DialogModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.KEYWORD_WINDOW:
                return <KeywordsModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.SPONSOR_WINDOW:
                return <SponsorModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.PADDLE_WINDOW:
                return <PaddleModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.TOUR_WINDOW:
                return <TourModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.TRANSFER_TEAM:
                return <TransferTeamModal size={size} show={modalOpen} onHide={handleCloseModal} />;
            case ModalAction.CHANGE_CLASS_TYPE:
                return (
                    <ChangeClassTypeModal size={size} show={modalOpen} onHide={handleCloseModal} />
                );
            default:
                return null;
        }
    }, [currentModal, handleCloseModal, modalOpen, size]);

    if (!modalOpen) {
        return null;
    }

    return renderModal;
};
