import { ClassFormatEnum, ClassTypeEnum } from './../types/enums';

export const classTypesData = [
    { value: ClassTypeEnum.SINGLES, label: 'enum-classTypeSingles' },
    { value: ClassTypeEnum.DOUBLES, label: 'enum-classTypeDoubles' },
    { value: ClassTypeEnum.TEAMS, label: 'enum-classTypeTeams' },
    // { value: ClassTypeEnum.CUP, label: 'enum-classTypeCup' },
];

export const classFormatData = [
    {
        value: ClassFormatEnum.GROUP,
        label: 'enum-formatGroup',
        isGroup: true,
        supportsDoubles: true,
    },
    {
        value: ClassFormatEnum.BRACKET,
        label: 'enum-formatDoubleElimination',
        isGroup: false,
        supportsDoubles: true,
    },
    {
        value: ClassFormatEnum.SINGLE_BRACKET,
        label: 'enum-formatSingleElimination',
        isGroup: false,
        supportsDoubles: true,
    },
    {
        value: ClassFormatEnum.GROUP_BRACKET,
        label: 'enum-formatGroupBracket',
        isGroup: true,
        supportsDoubles: true,
    },
    {
        value: ClassFormatEnum.BOX_LEAGUE,
        label: 'enum-formatBoxLeague',
        isGroup: true,
        supportsDoubles: false,
    },
];

export enum SaleStatusOptionEnum {
    CLOSED = 'enum-saleStatusClosed',
    OPEN = 'enum-saleStatusOpen',
}

export const SaleStatusOptionWithLabel = [
    { label: SaleStatusOptionEnum.CLOSED, value: false },
    { label: SaleStatusOptionEnum.OPEN, value: true },
];

export enum SaleStateClassOptionEnum {
    HIDE = 'enum-saleStateClassHide',
    SHOW = 'enum-saleStateClassShow',
}

export const SaleStateClassOptionWithLabel = [
    { label: SaleStateClassOptionEnum.HIDE, value: false },
    { label: SaleStateClassOptionEnum.SHOW, value: true },
];

export const initialData = {
    name: '',
    type: 1,
    minPlayers: 1,
    maxPlayers: 1,
    numberOfGroups: 1,
    surface: 1,
    ball: 1,
    isOnSale: 'true',
    format: 1,
    roundScoring: 'true',
    price: 1,
    vat: 1,
    salesStatus: SaleStateClassOptionWithLabel[1].value,
    stateOfClass: SaleStateClassOptionWithLabel[0].value,
    teams: [],
    tournamentGroups: [],
    tournamentCategories: [],
    tournamentMatches: [],
    isHasSubClasses: false,
    isDupr: false,
    requiresLicense: false,
};

export const saleStatusOption = ['enum-saleStatusClosed', 'enum-saleStatusOpen'];
export const saleStateClassOption = ['enum-saleStateClassHide', 'enum-saleStateClassShow'];
