import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, FormikProps } from 'formik';
import { ConfirmModal, CustomButton } from '../../../../../components';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { matchData } from '../../../../../constant/play';
import uniqId from 'uniqid';
import { IMatch, PlayValues } from '../PlayTag/PlayTag';
import { openModal } from '../../../../../features/modal/modalSlice';
import { ModalAction } from '../../../../../types/modal';
import { Court, MatchResult } from '../../../../../types/tournament';
import { Match } from '../Match/Match';
import { ClassFormatEnum } from '../../../../../types/enums';
import { selectClass } from '../../../../../features/class/classSelectors';
import { selectTournament } from '../../../../../features/tournament/tournamentSelectors';
import { getStringDateFormat } from '../../../../../utils/getStringDate';
import { assignTeamsForElmMatchesForGroup } from '../../../../../features/tournament/actionCreators';
import { CloseCircle } from '../../../../../assets/icons/CloseCircle';

interface MatchSectionProps {
    formikArg: FormikProps<PlayValues>;
    courts?: Array<Court>;
}

export const MatchSection: FC<MatchSectionProps> = React.memo(({ formikArg, courts = [] }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { classCollection, currentClassId } = useAppSelector(selectClass);
    const { startDate: tournamentStartDate } = useAppSelector(selectTournament)?.data || {};
    const currentClass = classCollection.find((data) => data.classId === currentClassId);

    const groups = formikArg.values.tournamentGroups;
    const matches = formikArg.values.tournamentMatches;
    const prefix = 'creativeDetail-';
    const buttonPrefix = 'button-';

    const setSortKey = () => {
        const matchArray = Object.values(matches)
            .filter(({ id }) => Boolean(id))
            .sort((a, b) => a.id?.toString().localeCompare(b.id?.toString()));
        const groupsWithMatches =
            (groups.length &&
                groups.reduce((acc, group) => {
                    const matchForGroupArray = matchArray.length
                        ? matchArray.filter((match) => match?.tournamentGroup?.id === group.id)
                        : [];
                    return [...acc, ...matchForGroupArray];
                }, [] as any[])) ||
            [];

        const eleminationMatches = matchArray.length
            ? matchArray.filter((match: IMatch) =>
                  currentClass?.format === ClassFormatEnum.GROUP ||
                  currentClass?.format === ClassFormatEnum.GROUP_BRACKET ||
                  currentClass?.format === ClassFormatEnum.BOX_LEAGUE
                      ? match?.tournamentGroup === null
                      : match
              )
            : [];

        const sortIds = [...(groupsWithMatches as any[]), ...eleminationMatches].map(
            (item, index) => ({
                id: item.id,
                sortKey: index + 1,
            })
        );

        sortIds.forEach((item) =>
            formikArg.setFieldValue(`tournamentMatches.${item.id}.sortKey`, item.sortKey)
        );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(setSortKey, [groups, matches, Object.keys(matches).length, currentClass]);

    const handleAddTeam = useCallback(
        (matchId: string | number, fieldName: string) => {
            dispatch(
                openModal({
                    currentModal: ModalAction.ADD_MATCH_TEAM,
                    modalOpen: true,
                    data: { matches, setMatches: formikArg.setFieldValue, matchId, fieldName },
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matches]
    );

    const handleAddPlayer = useCallback(
        (teamId: string | number, fieldName: string, matchId: string | number) => {
            dispatch(
                openModal({
                    currentModal: ModalAction.ADD_MATCH_PLAYER,
                    modalOpen: true,
                    data: {
                        matches,
                        setMatches: formikArg.setFieldValue,
                        teamId,
                        fieldName,
                        matchId,
                    },
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matches]
    );

    const handleOpenResultModal = useCallback(
        (results?: Array<MatchResult>, matchId?: number | string) => {
            dispatch(
                openModal({
                    currentModal: ModalAction.EDIT_MATCH_RESULT,
                    modalOpen: true,
                    data: { results, matchId },
                })
            );
        },
        [dispatch]
    );

    const eliminationMatches = Object.values(matches)
        .filter(({ id }) => Boolean(id))
        .sort((a, b) => Number(a.sortKey) - Number(b.sortKey))
        .filter((match) =>
            currentClass?.format === ClassFormatEnum.GROUP ||
            currentClass?.format === ClassFormatEnum.GROUP_BRACKET ||
            currentClass?.format === ClassFormatEnum.BOX_LEAGUE
                ? match?.tournamentGroup === null
                : match
        );

    const isAssignAvaliable = useMemo(() => {
        const matchesArray: Array<IMatch> = Object.values(matches);
        const isGroupMatchesCompleted = !((matchesArray as Array<IMatch>) || [])
            .filter(({ tournamentGroup }) => !!tournamentGroup)
            .find(({ results }) => {
                return !results || !results.length;
            });
        const noEliminationPlayersAssign = !((matchesArray as Array<IMatch>) || [])
            .filter(({ tournamentGroup }) => !tournamentGroup)
            .find(({ awayTeam, homeTeam }) => {
                return awayTeam || homeTeam;
            });

        return (
            !!matchesArray.length &&
            isGroupMatchesCompleted &&
            noEliminationPlayersAssign &&
            (Boolean(eliminationMatches.length) || Boolean(currentClass?.isHasSubClasses))
        );
    }, [matches, eliminationMatches, currentClass]);

    console.log({ eliminationMatches });

    return (
        <>
            <FieldArray name="tournamentMatches">
                {() =>
                    courts?.length ? (
                        <>
                            {((currentClass?.format === ClassFormatEnum.GROUP ||
                                currentClass?.format === ClassFormatEnum.GROUP_BRACKET ||
                                currentClass?.format === ClassFormatEnum.BOX_LEAGUE) &&
                                !currentClass?.parentClassId &&
                                groups.length &&
                                groups.map((group) => {
                                    if (typeof group.id !== 'number') {
                                        return null;
                                    }

                                    const groupMatches = Object.values(matches)
                                        .sort((a, b) => Number(a.sortKey) - Number(b.sortKey))
                                        .filter((match) => match?.tournamentGroup?.id === group.id);

                                    console.log({ groupMatches });

                                    return (
                                        <React.Fragment key={group.id}>
                                            <div className="my-3">
                                                <div>
                                                    <h5 className="font-weight-bold mb-0">
                                                        {group.name}
                                                    </h5>
                                                </div>
                                                {!!groupMatches.length && (
                                                    <ConfirmModal
                                                        onConfirm={() => {
                                                            formikArg.setFieldValue(
                                                                'tournamentMatches',
                                                                Object.values(matches)
                                                                    .filter(
                                                                        (m: IMatch) =>
                                                                            m.tournamentGroupId !==
                                                                            group.id
                                                                    )
                                                                    .reduce(
                                                                        (acc: any, item: any) => {
                                                                            acc[`${item.id}`] =
                                                                                item;
                                                                            return acc;
                                                                        },
                                                                        {}
                                                                    )
                                                            );
                                                        }}
                                                    >
                                                        <CustomButton
                                                            className="d-flex align-items-center"
                                                            btnText={t(
                                                                buttonPrefix + 'removeAllMatches'
                                                            )}
                                                            variant="outline-dark"
                                                        >
                                                            <CloseCircle />
                                                        </CustomButton>
                                                    </ConfirmModal>
                                                )}
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <CustomButton
                                                        variant="outline-dark"
                                                        btnText={t(buttonPrefix + 'addMatch')}
                                                        cb={() => {
                                                            const id = `99999999-${uniqId()}`;
                                                            formikArg.setFieldValue(
                                                                'tournamentMatches',
                                                                Object.assign(
                                                                    {
                                                                        [id]: {
                                                                            ...matchData,
                                                                            name: t(
                                                                                'match-defaultMatchName'
                                                                            ),
                                                                            id,
                                                                            startDate:
                                                                                getStringDateFormat(
                                                                                    tournamentStartDate ||
                                                                                        null
                                                                                ),
                                                                            homeTeamPlaceholder: '',
                                                                            awayTeamPlaceholder: '',
                                                                            court: courts[0].id,
                                                                            tournamentGroup: {
                                                                                id: group.id,
                                                                            },
                                                                        },
                                                                    },
                                                                    matches
                                                                )
                                                            );
                                                        }}
                                                        className="d-flex justify-content-between align-items-center"
                                                    >
                                                        <AddIcon />
                                                    </CustomButton>
                                                </div>
                                            </div>
                                            {groupMatches.length
                                                ? groupMatches.map((match, index) => {
                                                      return (
                                                          <div
                                                              key={match.id}
                                                              className="container-fluid px-0 my-2"
                                                          >
                                                              <Match
                                                                  index={index}
                                                                  matches={matches}
                                                                  match={match}
                                                                  isGroup={true}
                                                                  currentClass={currentClass}
                                                                  courts={courts}
                                                                  formikArg={formikArg}
                                                                  handleAddTeam={handleAddTeam}
                                                                  handleAddPlayer={handleAddPlayer}
                                                                  handleOpenResultModal={
                                                                      handleOpenResultModal
                                                                  }
                                                              />
                                                          </div>
                                                      );
                                                  })
                                                : null}
                                        </React.Fragment>
                                    );
                                })) ||
                                null}
                            <div className="my-3">
                                {!currentClass?.isHasSubClasses &&
                                    currentClass?.type !== 4 &&
                                    currentClass?.format !== ClassFormatEnum.BOX_LEAGUE && (
                                        <div>
                                            <h5 className="font-weight-bold mb-0">
                                                {t(prefix + 'elimination')}
                                            </h5>
                                        </div>
                                    )}
                                <div className="d-flex align-items-center">
                                    {!!eliminationMatches.length && (
                                        <ConfirmModal
                                            onConfirm={() => {
                                                formikArg.setFieldValue(
                                                    'tournamentMatches',
                                                    Object.values(matches)
                                                        .filter(
                                                            (m: IMatch) =>
                                                                m.tournamentGroupId !== null
                                                        )
                                                        .reduce((acc: any, item: any) => {
                                                            acc[`${item.id}`] = item;
                                                            return acc;
                                                        }, {})
                                                );
                                            }}
                                        >
                                            <CustomButton
                                                className="d-flex align-items-center"
                                                btnText={t(buttonPrefix + 'removeAllMatches')}
                                                variant="outline-dark"
                                            >
                                                <CloseCircle />
                                            </CustomButton>
                                        </ConfirmModal>
                                    )}
                                    {(currentClass?.format === ClassFormatEnum.GROUP ||
                                        currentClass?.format === ClassFormatEnum.GROUP_BRACKET) &&
                                        !currentClass?.parentClassId &&
                                        isAssignAvaliable && (
                                            <div className="my-3 d-flex align-items-center justify-content-between">
                                                <CustomButton
                                                    variant="outline-dark"
                                                    btnText={
                                                        currentClass?.isHasSubClasses
                                                            ? t(
                                                                  buttonPrefix +
                                                                      'assignTeamsToSubclasses'
                                                              )
                                                            : t(buttonPrefix + 'assignTeams')
                                                    }
                                                    className="d-flex justify-content-between align-items-center ms-3"
                                                    cb={() => {
                                                        dispatch(
                                                            assignTeamsForElmMatchesForGroup(
                                                                currentClassId as number
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                </div>
                                {!currentClass?.isHasSubClasses &&
                                    currentClass?.type !== 4 &&
                                    currentClass?.format !== ClassFormatEnum.BOX_LEAGUE && (
                                        <div className="d-flex align-items-center justify-content-end">
                                            <CustomButton
                                                variant="outline-dark"
                                                btnText={t(buttonPrefix + 'addMatch')}
                                                cb={() => {
                                                    const id = `99999999-${uniqId()}`;
                                                    formikArg.setFieldValue(
                                                        'tournamentMatches',
                                                        Object.assign(
                                                            {
                                                                [id]: {
                                                                    ...matchData,
                                                                    name: t(
                                                                        'match-defaultMatchName'
                                                                    ),
                                                                    id,
                                                                    homeTeamPlaceholder: '',
                                                                    awayTeamPlaceholder: '',
                                                                    startDate: getStringDateFormat(
                                                                        tournamentStartDate || null
                                                                    ),
                                                                    court: courts[0].id,
                                                                    tournamentGroup: null,
                                                                },
                                                            },
                                                            matches
                                                        )
                                                    );
                                                }}
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <AddIcon />
                                            </CustomButton>
                                        </div>
                                    )}
                            </div>
                            {eliminationMatches.length &&
                            !currentClass?.isHasSubClasses &&
                            currentClass?.type !== 4
                                ? eliminationMatches.map((match, index) => {
                                      return (
                                          <div key={match.id} className="container-fluid px-0 my-2">
                                              <Match
                                                  index={index}
                                                  matches={matches}
                                                  match={match}
                                                  currentClass={currentClass}
                                                  courts={courts}
                                                  formikArg={formikArg}
                                                  handleAddTeam={handleAddTeam}
                                                  handleAddPlayer={handleAddPlayer}
                                                  handleOpenResultModal={handleOpenResultModal}
                                              />
                                          </div>
                                      );
                                  })
                                : null}
                        </>
                    ) : (
                        <div className="d-flex justify-content-end fw-lighter">
                            <p>{t(prefix + 'warning6')}</p>
                        </div>
                    )
                }
            </FieldArray>
        </>
    );
});
