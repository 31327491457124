import { getAllSponsors } from './../../apis/sponsors';
import { getAllPaddles } from './../../apis/paddles';
import { getAllKeywords } from './../../apis/keywords';
import { IMatch } from './../../pages/tourDetails/TournamentPlay/components/PlayTag/PlayTag';
import { RootState } from './../../app/store';
import { selectTournament } from './tournamentSelectors';
import {
    generateCategoriesByClass,
    bulkDeleteGroupsByClass,
    fetchTournamentInfoData,
    fetchTournamentStatsAPI,
    generateGroupsRequest,
    updateTournamentInfoData,
} from './../../apis/tournament';
import { AppDispatch } from '../../app/store';
import {
    fetchTournamentData,
    updateMatchStatusData,
    updatePlayData,
    updatePlayStatus,
    updateTournamentData,
    generateMatchesData,
    getListTour,
} from '../../apis/tournament';
import {
    tournamentFetching,
    tournamentFetchingError,
    tournamentFetchingSuccess,
    tournamentStatsSuccess,
    tournamentUpdate,
    tournamentUpdateError,
    tournamentUpdatePlay,
    tournamentUpdateSuccess,
} from './tournamentSlice';
import {
    addMembers,
    setListKeywords,
    setListPaddle,
    setListSponsor,
} from '../setting/settingSlice';
import {
    classUpdating,
    classUpdatingError,
    classUpdatingSuccess,
    setClassCollection,
    classFirstUpdateSuccess,
    setCurrentName,
    classStatusSuccess,
    classStatusDataSuccess,
} from '../class/classSlice';
import { transformClassCollection } from '../../utils/transformClassCollection';
import { Class, MatchResult } from '../../types/tournament';
import { setListTour } from '../setting/settingSlice';
import { assignTeamsForElimination } from '../../apis/class';

export const fetchTournament = (id: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tournamentFetching());
        const data = await fetchTournamentData(id);
        dispatch(tournamentFetchingSuccess(data));
        if (data.officials.length) {
            dispatch(addMembers(data.officials));
        }
        if (data.classes.length) {
            const classCollection = transformClassCollection(data.classes);
            dispatch(setClassCollection(classCollection));
            dispatch(setCurrentName(classCollection[0].classId));
        }

        const listTour = await getListTour();
        dispatch(setListTour(listTour));
    } catch (e) {
        const error = e as Error;
        dispatch(tournamentFetchingError(error.message));
    }
};

export const updateTournament =
    (id: number, organizationId: number, updatedData: any, classId?: number | string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(tournamentUpdate());
            const data = await updateTournamentData(id, organizationId, updatedData);
            dispatch(tournamentUpdateSuccess(data));
            if (data.classes.length) {
                const classCollection = transformClassCollection(data.classes);
                dispatch(classFirstUpdateSuccess(classCollection as unknown as Class[]));
                // get new class id from database
                classId &&
                    typeof classId === 'string' &&
                    dispatch(setCurrentName(data.classes[data.classes.length - 1].id));
            }
        } catch (e) {
            const error = e as Error;
            dispatch(tournamentUpdateError(error.message));
        }
    };

export const updateTournamentInfo =
    (id: number, organizationId: number, updatedData: any, classId?: number | string) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(tournamentUpdate());
            const data = await updateTournamentInfoData(id, organizationId, updatedData);
            dispatch(tournamentUpdateSuccess(data));
        } catch (e) {
            const error = e as Error;
            dispatch(tournamentUpdateError(error.message));
        }
    };

export const generateGroups = (classId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(classUpdating());
        const data = await generateGroupsRequest(classId);
        dispatch(tournamentUpdatePlay(data));
    } catch (e) {
        const error = e as Error;
        dispatch(classUpdatingError(error.message));
    }
};

export const generateCategories =
    (tournamentId: number, classId: number | string, updatedData: any) =>
    async (dispatch: AppDispatch) => {
        try {
            let data;
            if (typeof classId === 'number') {
                data = await generateCategoriesByClass(classId);
                await dispatch(
                    tournamentUpdatePlay({
                        ...updatedData,
                        ...{ type: 4, tournamentCategories: data },
                    })
                );
            }
        } catch (e) {
            const error = e as Error;
            dispatch(classUpdatingError(error.message));
        }
    };

export const bulkDeleteByClass = (classId: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(classUpdating());
        const data = await bulkDeleteGroupsByClass(classId);
        dispatch(tournamentUpdatePlay(data));
    } catch (e) {
        const error = e as Error;
        dispatch(classUpdatingError(error.message));
    }
};

export const updatePlay =
    (classId: number, updatedData: any, isGenerateMatches?: boolean) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(classUpdating());
            const data = isGenerateMatches
                ? await generateMatchesData(classId)
                : await updatePlayData(classId, updatedData);
            dispatch(
                classUpdatingSuccess({
                    ...data,
                    tournamentMatches: data.tournamentMatches.map((tournament) => ({
                        ...tournament,
                        isGold: tournament.isGold.toString(),
                        court: tournament.court?.id || null,
                    })) as IMatch[],
                })
            );
            dispatch(tournamentUpdatePlay(data));
        } catch (e) {
            const error = e as Error;
            dispatch(classUpdatingError(error.message));
        }
    };

export const assignTeamsForElmMatchesForGroup =
    (classId: number) => async (dispatch: AppDispatch) => {
        try {
            dispatch(classUpdating());
            const data = await assignTeamsForElimination(classId);
            dispatch(
                classUpdatingSuccess({
                    ...data,
                    tournamentMatches: data.tournamentMatches.map((tournament) => ({
                        ...tournament,
                        isGold: tournament.isGold.toString(),
                        court: tournament.court?.id || null,
                    })) as IMatch[],
                })
            );
            dispatch(tournamentUpdatePlay(data));
        } catch (e) {
            const error = e as Error;
            dispatch(classUpdatingError(error.message));
        }
    };

export const updateStatusPlay =
    (matchId: number, updatedData: { status: number }) => async (dispatch: AppDispatch) => {
        try {
            dispatch(classUpdating());
            const data = await updatePlayStatus(matchId, updatedData);
            dispatch(
                classStatusSuccess({
                    ...data,
                    isGold: data.isGold.toString(),
                    court: data.court?.id || null,
                })
            );
        } catch (e) {
            const error = e as Error;
            dispatch(classUpdatingError(error.message));
        }
    };

export const updateStatusPlayData =
    (matchId: number, updatedData: { results: Array<MatchResult> }) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(classUpdating());
            const data = await updateMatchStatusData(matchId, updatedData);
            dispatch(classStatusDataSuccess(data));
        } catch (e) {
            const error = e as Error;
            dispatch(classUpdatingError(error.message));
        }
    };

export const fetchTournamentInfo =
    (id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const state = getState();
        const { data } = selectTournament(state);

        if (data?.id === id) return;

        try {
            dispatch(tournamentFetching());
            const [data, stats] = await Promise.all([
                fetchTournamentInfoData(id),
                fetchTournamentStatsAPI(id),
            ]);

            if (data.officials.length) {
                dispatch(addMembers(data.officials));
            }
            if (!state.settings.listTour.length) {
                const listTour = await getListTour();
                dispatch(setListTour(listTour));
            }

            if (!state.settings.listKeywords.length) {
                const keywords = await getAllKeywords();
                dispatch(setListKeywords(keywords));
            }

            if (!state.settings.listPaddle.length) {
                const paddles = await getAllPaddles();
                dispatch(setListPaddle(paddles));
            }

            if (!state.settings.listSponsor.length) {
                const sponsors = await getAllSponsors();
                dispatch(setListSponsor(sponsors));
            }

            dispatch(tournamentStatsSuccess(stats));
            dispatch(tournamentFetchingSuccess(data));
        } catch (e) {
            const error = e as Error;
            dispatch(tournamentFetchingError(error.message));
        }
    };
