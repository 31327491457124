import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalProps } from 'react-bootstrap';

interface ModalBoxProps extends ModalProps {
    btnText?: string;
    title?: string;
    children: React.ReactElement;
    isDisabled?: boolean;
    withoutMinHeight?: boolean;
    handleSave: () => void;
    centerButton?: {
        isDisabled?: boolean;
        text: string;
        onClick: () => void;
    };
}

export const ModalBox = (props: ModalBoxProps) => {
    const { t } = useTranslation();
    const {
        title,
        children,
        isDisabled = false,
        withoutMinHeight,
        handleSave,
        btnText,
        centerButton,
        ...rest
    } = props;

    const prefix = 'modal-';

    return (
        <Modal {...rest} aria-labelledby="contained-modal-title-vcenter" centered>
            {title ? (
                <Modal.Header className="d-flex align-items-center" closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h6 className="mb-0"> {title}</h6>
                    </Modal.Title>
                </Modal.Header>
            ) : null}
            <Modal.Body className="br-16">
                <div className={classnames({ 'min-h-1': !withoutMinHeight })}>{children}</div>
            </Modal.Body>
            <Modal.Footer className="p-0">
                <div className="w-100 d-flex m-0">
                    <Button
                        variant="outline-dark"
                        className="w-100 p-3 border-0 border-end rounded-0"
                        onClick={rest.onHide}
                    >
                        {t(prefix + 'cancel')}
                    </Button>
                    {centerButton && (
                        <Button
                            disabled={centerButton.isDisabled}
                            variant="outline-dark"
                            className="w-100 p-3 border-0 border-end rounded-0"
                            onClick={centerButton.onClick}
                        >
                            {centerButton.text}
                        </Button>
                    )}
                    <Button
                        disabled={isDisabled}
                        variant="outline-dark"
                        className="w-100 p-3 border-0 rounded-0"
                        onClick={handleSave}
                    >
                        {btnText || t(prefix + 'save')}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
