import { useCallback, useMemo, useState } from 'react';
import { Form, ModalProps, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../hooks/redux';
import { ModalBox } from '../../../../../components';
import { ClassTeam } from '../ClassesTag/ClassesTag';
import { ClassTypeEnum } from '../../../../../types/enums';
import { classTypesData } from '../../../../../constant/class';
import { useQuery } from '@tanstack/react-query';
import { getTournamentClassesWithTeams } from '../../../../../apis/class';
import { selectTournament } from '../../../../../features/tournament/tournamentSelectors';

export const TransferTeamModal = (props: ModalProps) => {
    const {
        team,
        transferToTeam,
    }: {
        team: ClassTeam;
        transferToTeam: (teamId: number, classId: number) => void;
    } = useAppSelector((state) => state.modals.data);
    const { data } = useAppSelector(selectTournament);
    const [selectedClassId, setSelectedClassId] = useState<string>('');

    const { t } = useTranslation();
    const prefix = 'transferTeamModal-';

    const tournamentId = data?.id;

    const { data: classData, isLoading } = useQuery({
        queryKey: ['tournament-class-teams', tournamentId],
        queryFn: () => getTournamentClassesWithTeams(tournamentId ?? 0),
        enabled: !!tournamentId,
    });

    // Determine if current team is singles or doubles based on players count
    const hasTwoPlayers = useMemo(() => team.users.length === 2, [team.users]);

    const currentClass = useMemo(
        () => classData?.find((c) => c.teams?.some((t) => t.id === team.id)),
        [classData, team]
    );

    const isSinglesOrDoubles = useMemo(
        () =>
            currentClass?.type === ClassTypeEnum.SINGLES ||
            currentClass?.type === ClassTypeEnum.DOUBLES,
        [currentClass]
    );

    const acceptedClassTypes = useMemo(
        () =>
            isSinglesOrDoubles
                ? [ClassTypeEnum.DOUBLES, ClassTypeEnum.SINGLES]
                : [currentClass?.type],
        [isSinglesOrDoubles, currentClass]
    );

    // Remove filtering and just exclude current class
    const availableClasses = useMemo(() => {
        return classData?.filter(
            (classItem) => classItem !== currentClass && acceptedClassTypes.includes(classItem.type)
        );
    }, [classData, currentClass, acceptedClassTypes]);

    // Validate selected class
    const validationError = useMemo(() => {
        if (isLoading) return null;
        if (!selectedClassId) return null;

        if (!tournamentId || !classData) {
            return t(prefix + 'unknownValidationError');
        }

        const selectedClass = classData.find((c) => c.id.toString() === selectedClassId);
        if (!selectedClass) return t(prefix + 'unknownValidationError');

        // Check if trying to put doubles team in singles class
        if (hasTwoPlayers && isSinglesOrDoubles && selectedClass.type === ClassTypeEnum.SINGLES) {
            return t(prefix + 'errorDoublesInSingles');
        }

        // Check for player conflicts
        const teamPlayers = team.users.map((user) => user.id);
        const selectedClassPlayers = selectedClass.teams?.flatMap((team) =>
            team.users.map((user) => user.id)
        );
        const hasConflictingPlayers = selectedClassPlayers?.some((player) =>
            teamPlayers.includes(player)
        );

        if (hasConflictingPlayers) {
            return t(prefix + 'errorPlayerConflict');
        }

        return null;
    }, [
        selectedClassId,
        classData,
        team,
        hasTwoPlayers,
        t,
        isSinglesOrDoubles,
        isLoading,
        tournamentId,
    ]);

    const handleSave = useCallback(() => {
        if (!selectedClassId || validationError) return;
        transferToTeam(Number(team.id), Number(selectedClassId));
        props.onHide?.();
    }, [selectedClassId, validationError, props, transferToTeam, team.id]);

    return (
        <ModalBox
            {...props}
            title={t(prefix + 'title')}
            handleSave={handleSave}
            isDisabled={!selectedClassId || Boolean(validationError) || isLoading}
        >
            <div className="d-flex flex-column gap-3">
                {/* Current Team Info */}
                <div className="border rounded p-3 bg-light">
                    <h6 className="mb-2 fw-bold">{team.name}</h6>
                    <div>
                        <strong>{t(prefix + 'players')}:</strong>{' '}
                        {team.users.map((user) => `${user.firstName} ${user.lastName}`).join(', ')}
                    </div>
                </div>

                {/* Available Classes Selection */}
                <Form.Group>
                    <Form.Label>{t(prefix + 'selectNewClass')}:</Form.Label>
                    <Form.Select
                        value={selectedClassId}
                        onChange={(e) => setSelectedClassId(e.target.value)}
                    >
                        <option value="">{t(prefix + 'selectClass')}</option>
                        {availableClasses?.map((classItem) => {
                            const classType = classTypesData.find(
                                (c) => c.value === classItem.type
                            );
                            return (
                                <option key={classItem.id} value={classItem.id}>
                                    {classItem.name} ({classType ? t(classType.label) : 'Unknown'})
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>

                {/* Validation Error Message */}
                {validationError && <div className="alert alert-danger">{validationError}</div>}
                {isLoading && selectedClassId && (
                    <Spinner animation="border" role="status" className="mx-auto">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )}

                {/* Info Message when valid selection */}
                {selectedClassId && !validationError && (
                    <div className="alert alert-info">{t(prefix + 'transferWarning')}</div>
                )}
            </div>
        </ModalBox>
    );
};
