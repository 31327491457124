export const getBool = (arg: string | boolean): boolean => {
    if (typeof arg === 'string') return arg === 'true';

    return arg;
};

export const getNumberBool = (arg: string | number | boolean): boolean => {
    if (typeof arg === 'string') return arg === 'true';
    if (typeof arg === 'number') return arg === 1;

    return arg;
};
