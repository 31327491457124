import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrayHelpers, FieldArray } from 'formik';
import { CustomButton, FormTextField, RoundedAvatar } from '../../../../../components';
import uniqId from 'uniqid';
import { useMutation, useQuery } from '@tanstack/react-query';

import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { ClassTeam, ClassValues } from '../ClassesTag/ClassesTag';
import { Accordion, Card, InputGroup, Form, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CustomAccordionHeader } from '../../../common/components/CustomAccordionHeader';
import { MemberTournamentCard } from '../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { classTypesData } from '../../../../../constant/class';
import { ModalAction, ModalName } from '../../../../../types/modal';
import { openModal } from '../../../../../features/modal/modalSlice';
import { CloseCircle } from '../../../../../assets/icons/CloseCircle';
import './index.scss';
import { ClassTypeEnum, TeamTournamentType, TypeError } from '../../../../../types/enums';
import { shuffleTeams } from './TeamSection.helpers';
import { ShuffleIcon } from '../../../../../assets/icons/ShuffleIcon';
import { ReactSortable } from 'react-sortablejs';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { fetchTicketsForClass, uploadImage } from '../../../../../apis/tournament';
import { RootState } from '../../../../../app/store';
import { useGetCurrentOrganization } from '../../../../../features/organizations/organizationsSelectors';
import { useParams } from 'react-router-dom';
import { generateTeamName } from '../../../../../utils/generateTeamName';
import { TicketDisplayStatus } from '../../../../../components/MemberTournamentCard/MemberTournamentCard';
import { ArrowUpRight } from 'react-bootstrap-icons';
import { transferTeamToClass } from '../../../../../apis/class';
import { Notification } from '../../../../../hooks/useNotification';

interface TeamSectionProps {
    setField: (field: string, value: any, shouldValidate?: boolean) => void;
    values: ClassValues & { id?: number };
}

export const TeamSection: FC<TeamSectionProps> = (props) => {
    const { t } = useTranslation();
    const { setField, values } = props;
    const teams = values.teams;
    const [activeKey, setActiveKey] = useState('' as any);
    const [draggedId, setDraggedId] = useState<string | number | null>(null);
    const currentType = useAppSelector((state: RootState) => state.class.currentType);
    const { communityId } = useParams<{ communityId: string }>();
    const classCollection = useAppSelector((state: RootState) => state.class.classCollection);

    const currentOrganization = useGetCurrentOrganization();

    const { data } = useQuery({
        queryKey: ['tickets', values.id],
        queryFn: () => fetchTicketsForClass(values.id?.toString() || ''),
        enabled: !!values.id,
    });

    const type = classTypesData.find((el) => el.value === currentType);
    const dispatch = useAppDispatch();
    const isTeamLeague = type?.value === ClassTypeEnum.TEAMS;
    // We lock the number of teams to 12. But BE can support more, so this value can be increased without any issues.
    const isTeamLeagueMax = isTeamLeague && teams.length >= 12;

    const prefix = 'creativeDetail-';
    const inputPrefix = 'input-';
    const modalPrefix = 'modal-';
    const buttonPrefix = 'button-';

    const showShuffleButton = teams.find(({ seed, shuffled }) => !seed || shuffled);

    const handleOpenModal = (teamId: string | number) => {
        dispatch(
            openModal({
                currentModal: ModalAction.ADD_MEMBER,
                modalOpen: true,
                data: {
                    teams,
                    setField,
                    teamId,
                    type,
                    title: t(modalPrefix + 'addPlayers'),
                    modalName: ModalName.ADD_TEAM_PLAYERS_CLASS,
                },
            })
        );
    };

    const handleOpenCaptainModal = (teamId: string | number) => {
        dispatch(
            openModal({
                currentModal: ModalAction.ADD_CAPTAIN,
                modalOpen: true,
                data: {
                    teams,
                    setField,
                    teamId,
                    type,
                    title: t(modalPrefix + 'addCaptain'),
                    modalName: ModalName.ADD_TEAM_CAPTAIN_CLASS,
                },
            })
        );
    };

    const onChange = (imageList: ImageListType, id: string | number) => {
        const formData = new FormData();
        formData.append('file', imageList[0]?.file || '');
        imageList[0].file &&
            uploadImage(formData, 'team')
                .then((data) => {
                    const updatedTeams = teams.map((team) =>
                        team.id === id ? { ...team, logo: data.url } : team
                    );
                    setField('teams', updatedTeams);
                })
                .catch((e) => {
                    console.log(e);
                });
    };

    const handleOpenTeamModal = useCallback(
        (push: (obj: any) => void) => {
            dispatch(
                openModal({
                    currentModal: ModalAction.ADD_TEAM,
                    modalOpen: true,
                    data: {
                        selected: teams,
                        title: t(modalPrefix + 'addTeam'),
                        push,
                        communityId,
                    },
                })
            );
        },
        [dispatch, t, teams, communityId]
    );

    const transferMutation = useMutation({
        mutationFn: ({ classId, teamId }: { classId: number; teamId: number }) => {
            return transferTeamToClass(classId, teamId);
        },
        onSuccess: (data, variables) => {
            setField(
                'teams',
                teams.filter((team) => team.id.toString() !== variables.teamId.toString())
            );
            Notification(TypeError.success, t(prefix + 'transfer-success'));
        },
        onError: (error) => {
            Notification(TypeError.error, t(prefix + 'transfer-error'));
        },
    });

    const transferToTeam = (teamId: number, classId: number) => {
        transferMutation.mutate({ classId, teamId });
    };

    const handleTransferTeamModal = (team: ClassTeam) => {
        dispatch(
            openModal({
                currentModal: ModalAction.TRANSFER_TEAM,
                modalOpen: true,
                data: {
                    team,
                    transferToTeam,
                },
            })
        );
    };

    return (
        <FieldArray name="teams">
            {({ push, remove }: ArrayHelpers) => (
                <>
                    <div className="my-3 d-flex justify-content-between align-items-center">
                        <h4 className="font-weight-bold">{t(prefix + 'teams')}</h4>
                        <div className="d-flex">
                            {showShuffleButton && (
                                <CustomButton
                                    variant="dark"
                                    btnText={t(prefix + 'shuffleRank')}
                                    cb={() => {
                                        const teamsToUpdate = shuffleTeams(teams);
                                        const newTeams = teamsToUpdate.sort(
                                            (a, b) => a.seed - b.seed
                                        );
                                        setField('teams', [
                                            ...newTeams.filter((i) => Boolean(i.seed)),
                                            ...newTeams.filter((i) => !Boolean(i.seed)),
                                        ]);
                                    }}
                                    className="d-flex justify-content-center align-items-center me-1"
                                >
                                    <ShuffleIcon h="14" w="14" />
                                </CustomButton>
                            )}
                            <CustomButton
                                variant="outline-dark"
                                btnText={t(prefix + 'addTeam')}
                                disabled={isTeamLeagueMax}
                                cb={() => {
                                    push({
                                        id: uniqId(),
                                        name: `Team ${teams.length + 1}`,
                                        users: [],
                                        captain: null,
                                        logo: null,
                                        seed: 0,
                                        organization: currentOrganization,
                                        type: isTeamLeague
                                            ? TeamTournamentType.Team
                                            : TeamTournamentType.Regular,
                                    });
                                }}
                                className="d-flex justify-content-between align-items-center me-1"
                            >
                                <AddIcon />
                            </CustomButton>
                            {isTeamLeague && (
                                <CustomButton
                                    variant="outline-dark"
                                    btnText={t(prefix + 'addExistingTeam')}
                                    disabled={isTeamLeagueMax}
                                    cb={() => handleOpenTeamModal(push)}
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <AddIcon />
                                </CustomButton>
                            )}
                        </div>
                    </div>
                    {teams.length ? (
                        <ReactSortable
                            list={[...teams].map((v) => ({ ...v, chosen: true }))}
                            setList={(updatedList, event, store) => {
                                if (store?.dragging) {
                                    setField(
                                        'teams',
                                        updatedList.map(({ chosen, ...rest }, i) => {
                                            if (rest.seed || rest.id === draggedId) {
                                                rest.seed = i + 1;

                                                if (rest.id === draggedId) {
                                                    rest.shuffled = false;
                                                }
                                            }
                                            return rest;
                                        })
                                    );
                                }
                            }}
                        >
                            {teams.map((team, index) => {
                                const isBtnActive =
                                    (type?.value === ClassTypeEnum.SINGLES &&
                                        team.users?.length >= 1) ||
                                    (type?.value === ClassTypeEnum.DOUBLES &&
                                        team.users?.length >= 2) ||
                                    false;

                                const backendTeam = classCollection
                                    ?.find((c) => c.classId === values.classId)
                                    ?.teams?.find((t) => t.id === team.id);

                                const ticketsForNonTeamPlayers =
                                    data?.filter(
                                        (t) =>
                                            t.teamId === team.id &&
                                            !backendTeam?.users.some((u) => u.id === t.user.id)
                                    ) || [];

                                // Only check for missing tickets for users that exist in backend
                                const playersMissingTickets =
                                    team.users.filter(
                                        (u) =>
                                            backendTeam?.users?.some((bu) => bu.id === u.id) &&
                                            !data?.find(
                                                (t) => t.teamId === team.id && t.user.id === u.id
                                            )
                                    ) || [];

                                const formPlayersJoined = team.users.map((u) => u.id).join(',');
                                const backendPlayersJoined = backendTeam?.users
                                    .map((u) => u.id)
                                    .join(',');
                                const formSeed = team.seed;
                                const backendSeed = backendTeam?.seed;

                                const hasUnsavedTeamChanges =
                                    team.name !== backendTeam?.name ||
                                    formPlayersJoined !== backendPlayersJoined ||
                                    formSeed !== backendSeed;

                                const transferButton = (
                                    <CustomButton
                                        className="d-flex align-items-center mr-2"
                                        btnText={t(prefix + 'transferToAnotherClass')}
                                        variant="outline-dark"
                                        onClick={() => {
                                            handleTransferTeamModal(team);
                                        }}
                                        disabled={hasUnsavedTeamChanges}
                                    >
                                        <ArrowUpRight />
                                    </CustomButton>
                                );

                                const displayTicketWarning =
                                    (!!ticketsForNonTeamPlayers?.length ||
                                        !!playersMissingTickets?.length) &&
                                    type?.value !== ClassTypeEnum.TEAMS;
                                return (
                                    <div
                                        key={index}
                                        className="container-fluid px-0 my-3"
                                        onMouseDown={() => setDraggedId(team.id)}
                                        onTouchStart={() => setDraggedId(team.id)}
                                    >
                                        <Accordion
                                            alwaysOpen={false}
                                            activeKey={activeKey}
                                            onSelect={setActiveKey}
                                            className="my-3"
                                        >
                                            <Card>
                                                <Card.Header className="p-0 bg-white border-0">
                                                    <CustomAccordionHeader
                                                        eventKey={index.toString()}
                                                    >
                                                        <InputGroup className="flex-nowrap">
                                                            <Form.Text className="mt-0">
                                                                <div className="d-flex p-2 h-100 flex-column justify-content-center">
                                                                    {(team.logo && (
                                                                        <RoundedAvatar
                                                                            name="flag"
                                                                            avatar={team.logo}
                                                                            alt="Team logo"
                                                                        />
                                                                    )) || (
                                                                        <div className="raqt__seed-holder">
                                                                            {team.seed}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Form.Text>
                                                            <FormTextField
                                                                className="py-0 border-1 w-100 border-white"
                                                                index={index}
                                                                name={`teams.${index}.name`}
                                                                placeholder={t(
                                                                    inputPrefix +
                                                                        'placeholderTeamName'
                                                                )}
                                                                type="text"
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name={`teams.${index}.shuffled`}
                                                            />
                                                        </InputGroup>
                                                    </CustomAccordionHeader>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={index.toString()}>
                                                    {(activeKey === index.toString() && (
                                                        <Card.Body className="p-2">
                                                            <div className="w-100 d-flex flex-wrap flex-column flex-lg-row">
                                                                <div className="col-lg-3 d-flex flex-column flex-lg-row align-items-end align-items-lg-start px-0 px-lg-2">
                                                                    <div className="d-flex flex-column">
                                                                        {isTeamLeague && (
                                                                            <>
                                                                                {team.logo && (
                                                                                    <img
                                                                                        style={{
                                                                                            objectFit:
                                                                                                'cover',
                                                                                            width: '100%',
                                                                                            maxWidth:
                                                                                                '240px',
                                                                                            aspectRatio:
                                                                                                '1/1',
                                                                                        }}
                                                                                        className={
                                                                                            'rounded-circle'
                                                                                        }
                                                                                        src={
                                                                                            team.logo
                                                                                        }
                                                                                        alt={
                                                                                            team.name +
                                                                                            ' team logo'
                                                                                        }
                                                                                    />
                                                                                )}

                                                                                <ImageUploading
                                                                                    value={[]}
                                                                                    onChange={(
                                                                                        imageList: ImageListType
                                                                                    ) => {
                                                                                        onChange(
                                                                                            imageList,
                                                                                            team.id
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {({
                                                                                        onImageUpload,
                                                                                    }) => (
                                                                                        <CustomButton
                                                                                            variant="outline-dark"
                                                                                            btnText={t(
                                                                                                buttonPrefix +
                                                                                                    'addTeamIcon'
                                                                                            )}
                                                                                            cb={
                                                                                                onImageUpload
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </ImageUploading>
                                                                            </>
                                                                        )}
                                                                        {isTeamLeague && (
                                                                            <CustomButton
                                                                                disabled={
                                                                                    isBtnActive
                                                                                }
                                                                                className="d-flex justify-content-evenly align-items-center"
                                                                                variant="outline-dark"
                                                                                btnText={t(
                                                                                    buttonPrefix +
                                                                                        'addCaptain'
                                                                                )}
                                                                                cb={() => {
                                                                                    handleOpenCaptainModal(
                                                                                        team.id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <AddIcon />
                                                                            </CustomButton>
                                                                        )}
                                                                        <CustomButton
                                                                            disabled={isBtnActive}
                                                                            className="d-flex justify-content-evenly align-items-center"
                                                                            variant="outline-dark"
                                                                            btnText={t(
                                                                                buttonPrefix +
                                                                                    'addPlayers'
                                                                            )}
                                                                            cb={() => {
                                                                                handleOpenModal(
                                                                                    team.id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <AddIcon />
                                                                        </CustomButton>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={`col d-flex flex-wrap ${
                                                                        isTeamLeague
                                                                            ? 'flex-column col-lg-4'
                                                                            : ''
                                                                    } px-0`}
                                                                >
                                                                    {isTeamLeague &&
                                                                    team.captain &&
                                                                    team.captain.length > 0 &&
                                                                    team.captain[0]?.id ? (
                                                                        <>
                                                                            <h5 className="font-weight-normal mb-0">
                                                                                {t(
                                                                                    prefix +
                                                                                        'teamCaptain'
                                                                                )}
                                                                            </h5>
                                                                            <div>
                                                                                <React.Fragment>
                                                                                    <MemberTournamentCard
                                                                                        ticketStatus="notUsed"
                                                                                        member={
                                                                                            team
                                                                                                .captain[0]
                                                                                        }
                                                                                    />
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            <p className="fw-lighter mb-0">
                                                                                {t(
                                                                                    prefix +
                                                                                        'chooseCaptain'
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    <FieldArray
                                                                        name={`teams.${index}.users`}
                                                                    >
                                                                        {({
                                                                            remove,
                                                                            replace,
                                                                        }: ArrayHelpers) => {
                                                                            return team.users
                                                                                .length ? (
                                                                                <>
                                                                                    {team.users
                                                                                        .filter(
                                                                                            (
                                                                                                v,
                                                                                                index
                                                                                            ) => {
                                                                                                const maxLength =
                                                                                                    type?.value ===
                                                                                                    ClassTypeEnum.SINGLES
                                                                                                        ? 1
                                                                                                        : 2;
                                                                                                return isTeamLeague
                                                                                                    ? true
                                                                                                    : index <
                                                                                                          maxLength;
                                                                                            }
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                user,
                                                                                                i
                                                                                            ) => {
                                                                                                let ticket =
                                                                                                    data?.find(
                                                                                                        (
                                                                                                            t
                                                                                                        ) =>
                                                                                                            t.teamId ===
                                                                                                                team.id &&
                                                                                                            t
                                                                                                                .user
                                                                                                                .id ===
                                                                                                                user.id
                                                                                                    );
                                                                                                // Check if the user exists in the original class data to determine if they're newly added
                                                                                                const isNewlyAddedUser =
                                                                                                    !classCollection
                                                                                                        ?.find(
                                                                                                            (
                                                                                                                c
                                                                                                            ) =>
                                                                                                                c.classId ===
                                                                                                                values.classId
                                                                                                        )
                                                                                                        ?.teams?.find(
                                                                                                            (
                                                                                                                t
                                                                                                            ) =>
                                                                                                                t.id ===
                                                                                                                team.id
                                                                                                        )
                                                                                                        ?.users?.find(
                                                                                                            (
                                                                                                                u
                                                                                                            ) =>
                                                                                                                u.id ===
                                                                                                                user.id
                                                                                                        );

                                                                                                let ticketStatus: TicketDisplayStatus =
                                                                                                    ticket
                                                                                                        ? 'active'
                                                                                                        : 'missing';
                                                                                                if (
                                                                                                    isNewlyAddedUser
                                                                                                ) {
                                                                                                    // If player is already in another team, we use that ticket. Otherwise a new one will be created on save.
                                                                                                    ticket =
                                                                                                        data?.find(
                                                                                                            (
                                                                                                                t
                                                                                                            ) =>
                                                                                                                t
                                                                                                                    .user
                                                                                                                    .id ===
                                                                                                                user.id
                                                                                                        );
                                                                                                    if (
                                                                                                        ticket
                                                                                                    ) {
                                                                                                        ticketStatus =
                                                                                                            'active';
                                                                                                    } else {
                                                                                                        ticketStatus =
                                                                                                            'pending';
                                                                                                    }
                                                                                                }
                                                                                                if (
                                                                                                    type?.value ===
                                                                                                    ClassTypeEnum.TEAMS
                                                                                                ) {
                                                                                                    ticketStatus =
                                                                                                        'notUsed';
                                                                                                }
                                                                                                return (
                                                                                                    <div
                                                                                                        key={
                                                                                                            user.id
                                                                                                        }
                                                                                                    >
                                                                                                        {i ===
                                                                                                            0 &&
                                                                                                        isTeamLeague ? (
                                                                                                            <h5 className="font-weight-normal mb-0 mt-3">
                                                                                                                {t(
                                                                                                                    prefix +
                                                                                                                        'teamPlayers'
                                                                                                                )}
                                                                                                            </h5>
                                                                                                        ) : null}
                                                                                                        <React.Fragment>
                                                                                                            <MemberTournamentCard
                                                                                                                ticketStatus={
                                                                                                                    ticketStatus
                                                                                                                }
                                                                                                                onClick={() => {
                                                                                                                    const newUsers =
                                                                                                                        team.users.filter(
                                                                                                                            (
                                                                                                                                u
                                                                                                                            ) =>
                                                                                                                                u.id !==
                                                                                                                                user.id
                                                                                                                        );
                                                                                                                    const newName =
                                                                                                                        generateTeamName(
                                                                                                                            newUsers,
                                                                                                                            type,
                                                                                                                            team
                                                                                                                        );
                                                                                                                    setField(
                                                                                                                        'teams',
                                                                                                                        teams.map(
                                                                                                                            (
                                                                                                                                t
                                                                                                                            ) =>
                                                                                                                                t.id ===
                                                                                                                                team.id
                                                                                                                                    ? {
                                                                                                                                          ...t,
                                                                                                                                          name: newName,
                                                                                                                                          users: newUsers,
                                                                                                                                      }
                                                                                                                                    : t
                                                                                                                        )
                                                                                                                    );
                                                                                                                }}
                                                                                                                member={
                                                                                                                    user
                                                                                                                }
                                                                                                                ticket={
                                                                                                                    ticket
                                                                                                                }
                                                                                                            />
                                                                                                        </React.Fragment>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                </>
                                                                            ) : (
                                                                                <div className="d-flex justify-content-center align-items-center">
                                                                                    <p className="fw-lighter mb-0">
                                                                                        {t(
                                                                                            prefix +
                                                                                                'choosePlayers'
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </FieldArray>
                                                                </div>
                                                            </div>
                                                            <div className="col-5 col-lg-3 p-2">
                                                                <FormTextField
                                                                    label={t(prefix + 'seed')}
                                                                    className="py-0 border-1 w-100"
                                                                    index={index}
                                                                    name={`teams.${index}.seed`}
                                                                    placeholder={t(
                                                                        inputPrefix +
                                                                            'placeholderSeed'
                                                                    )}
                                                                    type="text"
                                                                />
                                                            </div>
                                                            {displayTicketWarning && (
                                                                <Alert variant="warning">
                                                                    <Alert.Heading>
                                                                        {t(
                                                                            prefix +
                                                                                'ticketsWarningTitle'
                                                                        )}
                                                                    </Alert.Heading>

                                                                    {playersMissingTickets?.length >
                                                                        0 && (
                                                                        <>
                                                                            <h6 className="mb-0">
                                                                                {t(
                                                                                    prefix +
                                                                                        'ticketsWarningMissingTickets'
                                                                                )}
                                                                            </h6>
                                                                            <ul className="mb-0">
                                                                                {playersMissingTickets.map(
                                                                                    (player) => (
                                                                                        <li
                                                                                            key={
                                                                                                player.id
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                player.firstName
                                                                                            }{' '}
                                                                                            {
                                                                                                player.lastName
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </>
                                                                    )}

                                                                    {ticketsForNonTeamPlayers?.length >
                                                                        0 && (
                                                                        <>
                                                                            <h6 className="mb-0">
                                                                                {t(
                                                                                    prefix +
                                                                                        'ticketsWarningExtraTickets'
                                                                                )}
                                                                            </h6>
                                                                            <ul className="mb-0">
                                                                                {ticketsForNonTeamPlayers.map(
                                                                                    (ticket) => (
                                                                                        <li
                                                                                            key={
                                                                                                ticket.id
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                ticket
                                                                                                    .user
                                                                                                    .firstName
                                                                                            }{' '}
                                                                                            {
                                                                                                ticket
                                                                                                    .user
                                                                                                    .lastName
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </Alert>
                                                            )}
                                                            <div className="d-flex justify-content-start justify-content-lg-end">
                                                                <div className="flex-grow-1">
                                                                    {/* <ImageUploading
                                                                        value={[]}
                                                                        onChange={(
                                                                            imageList: ImageListType
                                                                        ) => {
                                                                            onChange(imageList, team.id);
                                                                        }}
                                                                    >
                                                                        {({ onImageUpload }) => {
                                                                            return (
                                                                                <div className="d-flex justify-content-start px-0 px-lg-2">
                                                                                    <CustomButton
                                                                                        className="d-flex justify-content-evenly align-items-center"
                                                                                        variant="outline-dark"
                                                                                        btnText={t(
                                                                                            buttonPrefix +
                                                                                                'addTeamIcon'
                                                                                        )}
                                                                                        cb={onImageUpload}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </ImageUploading> */}
                                                                </div>
                                                                {hasUnsavedTeamChanges ? (
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip
                                                                                id={`transfer-tooltip-${team.id}`}
                                                                            >
                                                                                {t(
                                                                                    prefix +
                                                                                        'cantTransferDirtyForm'
                                                                                )}
                                                                            </Tooltip>
                                                                        }
                                                                        placement="top"
                                                                    >
                                                                        <span>
                                                                            {transferButton}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    transferButton
                                                                )}
                                                                <CustomButton
                                                                    className="d-flex align-items-center"
                                                                    btnText={t(
                                                                        buttonPrefix + 'removeTeam'
                                                                    )}
                                                                    variant="outline-dark"
                                                                    onClick={() => {
                                                                        remove(index);
                                                                    }}
                                                                >
                                                                    <CloseCircle />
                                                                </CustomButton>
                                                            </div>
                                                        </Card.Body>
                                                    )) || (
                                                        <Card.Body>
                                                            <div className="team-section__dummy"></div>
                                                        </Card.Body>
                                                    )}
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>
                                );
                            })}
                        </ReactSortable>
                    ) : null}
                </>
            )}
        </FieldArray>
    );
};
