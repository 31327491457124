import { ClassTypeEnum } from './../../types/enums';
import { Class } from './../../types/tournament';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassValues } from '../../pages/tourDetails/TournamentClasses/components/ClassesTag/ClassesTag';
import { classTypesData } from '../../constant/class';
import { Group, MatchResult } from '../../types/tournament';
import { IMatch } from './../../pages/tourDetails/TournamentPlay/components/PlayTag/PlayTag';

interface ClassState {
    currentClassId: string | number;
    isLoading: boolean;
    error: string;
    currentTeamId: string | number;
    currentType: number;
    classCollection: Array<ClassValues>;
}

const initialState: ClassState = {
    currentClassId: '',
    isLoading: false,
    error: '',
    currentType: classTypesData[0].value,
    currentTeamId: '',
    classCollection: [],
};

export const classSlice = createSlice({
    name: 'class',
    initialState,
    reducers: {
        newClassName: (state, action: PayloadAction<ClassValues>) => {
            return {
                ...state,
                classCollection: [...state.classCollection, action.payload],
                isLoading: false,
                currentClassId: action.payload.classId,
            };
        },
        setClassCollection: (state, action: PayloadAction<ClassValues[]>) => {
            return { ...state, classCollection: action.payload };
        },
        //TO DO: Rename this. ID IS NUMBER!!!!!
        setCurrentName: (state, action: PayloadAction<string | number>) => {
            const currentClass =
                state.classCollection.find(({ classId }) => classId === action.payload) ||
                ({} as any);
            return {
                ...state,
                currentClassId: action.payload,
                currentType: currentClass.type || ClassTypeEnum.SINGLES,
            };
        },
        setCurrentType: (state, action: PayloadAction<number>) => {
            return { ...state, currentType: action.payload };
        },
        classUpdating: (state) => {
            state.isLoading = true;
        },
        clearClass: () => {
            return initialState;
        },
        classUpdatingSuccess: (
            state,
            action: PayloadAction<{
                id: number;
                tournamentGroups: Group[];
                tournamentMatches: IMatch[];
            }>
        ) => {
            state.isLoading = false;
            state.error = '';
            state.classCollection = state.classCollection.map((data) => {
                return data.classId === action.payload.id
                    ? {
                          ...data,
                          tournamentGroups: action.payload.tournamentGroups,
                          tournamentMatches: action.payload.tournamentMatches.map((match) => ({
                              ...match,
                              court: match.court,
                          })),
                      }
                    : data;
            });
        },
        classUpdatingError: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        classStatusSuccess: (state, action: PayloadAction<IMatch>) => {
            state.isLoading = false;
            if (typeof state.currentClassId === 'number') {
                const index = state.classCollection.findIndex(
                    (data) => data.classId === state.currentClassId
                );

                state.classCollection[index] = {
                    ...state.classCollection[index],
                    tournamentMatches: state.classCollection[index].tournamentMatches.map((match) =>
                        match.id === action.payload.id
                            ? { ...match, status: action.payload.status }
                            : match
                    ),
                };
            }
        },
        classStatusDataSuccess: (
            state,
            action: PayloadAction<{ id: number; results: Array<MatchResult> }>
        ) => {
            state.isLoading = false;
            if (typeof state.currentClassId === 'number') {
                const index = state.classCollection.findIndex(
                    (data) => data.classId === state.currentClassId
                );
                state.classCollection[index] = {
                    ...state.classCollection[index],
                    tournamentMatches: state.classCollection[index].tournamentMatches.map((match) =>
                        match.id === action.payload.id
                            ? { ...match, results: action.payload.results }
                            : match
                    ),
                };
            }
        },
        classFirstUpdateSuccess: (state, action: PayloadAction<Class[]>) => {
            // action.payload.forEach(item => {
            //     item.teams = item.teams.sort((a, b) => (a.seed || 0) - (b.seed || 0))
            // });
            return {
                ...state,
                isLoading: false,
                classCollection: action.payload as unknown as ClassValues,
                currentClassId:
                    state.currentClassId ||
                    action.payload[0]?.id ||
                    (action.payload[0] as any)?.classId ||
                    null,
                currentType: action.payload[0]?.type || null,
            } as unknown as ClassState;
        },

        classByIdUpdateSuccess: (
            state,
            action: PayloadAction<{
                oldId: string | number;
                newData: Class;
            }>
        ) => {
            const newClassList: ClassValues[] = JSON.parse(JSON.stringify(state.classCollection));
            const classIndex = newClassList.findIndex(
                ({ classId }) => classId === action.payload.oldId
            );

            if (typeof classIndex === 'number' && newClassList[classIndex]) {
                newClassList.splice(classIndex, 1, {
                    ...action.payload.newData,
                    classId: action.payload.newData.id,
                } as unknown as ClassValues);
            } else {
                newClassList.push({
                    ...action.payload.newData,
                    classId: action.payload.newData.id,
                } as unknown as ClassValues);
            }

            return {
                ...state,
                isLoading: false,
                currentClassId: action.payload.newData.id,
                classCollection: newClassList,
            } as unknown as ClassState;
        },
        classSetSingleClass: (state, action: PayloadAction<Class>) => {
            return {
                ...state,
                classCollection: state.classCollection.map((item) => {
                    if (item.classId === action.payload.id) {
                        return {
                            ...action.payload,
                            classId: action.payload.id,
                        } as unknown as ClassValues;
                    } else {
                        return item;
                    }
                }),
            };
        },
        classDeleteSuccess: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                classCollection: state.classCollection.filter(
                    ({ classId }) => classId !== action.payload
                ),
            };
        },
    },
});

export const {
    newClassName,
    setCurrentName,
    setCurrentType,
    setClassCollection,
    classUpdating,
    classUpdatingSuccess,
    classUpdatingError,
    classStatusSuccess,
    classStatusDataSuccess,
    clearClass,
    classFirstUpdateSuccess,
    classByIdUpdateSuccess,
    classSetSingleClass,
    classDeleteSuccess,
} = classSlice.actions;

export default classSlice.reducer;
