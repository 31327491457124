import { ClassTeam } from '../pages/tourDetails/TournamentClasses/components/ClassesTag/ClassesTag';
import { ClassTypeEnum } from '../types/enums';
import { User } from '../types/tournament';

export const generateTeamName = (
    users: User[],
    type:
        | {
              value: ClassTypeEnum;
              label: string;
          }
        | undefined,
    team: ClassTeam
) => {
    let userTitleSingleOrDouble = '';

    if (type?.value === ClassTypeEnum.DOUBLES || type?.value === ClassTypeEnum.SINGLES) {
        const usersTitle = (idx: number) => {
            if (users[idx]?.firstName && users[idx]?.lastName) {
                return `${users[idx].firstName || ''} ${users[idx].lastName}`;
            }
            return users[idx]?.userName;
        };
        if (users.length === 0) {
            return '';
        }

        userTitleSingleOrDouble =
            users.length === 1 ? usersTitle(0) : `${usersTitle(0)} / ${usersTitle(1)}`;
    }

    const name =
        type?.value === ClassTypeEnum.TEAMS
            ? team.name
            : type?.label === 'Cup'
            ? team.name
            : userTitleSingleOrDouble.length > 0
            ? userTitleSingleOrDouble
            : '';

    return name;
};
