import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrayHelpers, FieldArray, FormikProps } from 'formik';
import { Accordion, Card, Form, InputGroup } from 'react-bootstrap';
import { CustomButton, FormTextField } from '../../../../../components';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { PlayValues } from '../PlayTag/PlayTag';
import { CustomAccordionHeader } from '../../../common/components/CustomAccordionHeader';
import { openModal } from '../../../../../features/modal/modalSlice';
import { ModalAction } from '../../../../../types/modal';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { ClassTeam } from '../../../TournamentClasses/components/ClassesTag/ClassesTag';
import uniqId from 'uniqid';
import { CloseCircle } from '../../../../../assets/icons/CloseCircle';
import { TeamPlayCard } from '../TeamPlayCard';
import { Group } from '../../../../../types/tournament';
import { GroupIcon } from '../../../../../assets/icons/GroupIcon';
import { selectClass } from '../../../../../features/class/classSelectors';
import { generateGroups } from '../../../../../features/tournament/actionCreators';

interface GroupSectionProps {
    formikArg: FormikProps<PlayValues>;
    teams: ClassTeam[];
    currentClass: null | unknown | any;
}

export const GroupSection: FC<GroupSectionProps> = (props) => {
    const { t } = useTranslation();
    const { teams, formikArg, currentClass } = props;

    const groups = formikArg.values.tournamentGroups;
    const matches = formikArg.values.tournamentMatches;
    const type = formikArg.initialValues.type;
    const dispatch = useAppDispatch();
    const { currentClassId } = useAppSelector(selectClass);

    const prefix = 'creativeDetail-';
    const inputPrefix = 'input-';
    const buttonPrefix = 'button-';

    const handleOpenModal = useCallback(
        (group: Group) => {
            dispatch(
                openModal({
                    currentModal: ModalAction.ADD_GROUPS_PLAY,
                    modalOpen: true,
                    data: {
                        currentGroup: group,
                        registretedTeams: teams,
                        setGroups: formikArg.setFieldValue,
                        groups,
                    },
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [groups, teams]
    );

    return (
        <FieldArray name="tournamentGroups">
            {({ push, remove }: ArrayHelpers) =>
                teams.length ? (
                    <>
                        {type === 4 ? (
                            <>
                                {currentClass?.tournamentCategories &&
                                currentClass?.tournamentCategories.length > 0 &&
                                currentClass?.tournamentCategories.some(
                                    (item: any) => item?.isActive === 1
                                ) ? (
                                    <div className="my-3 d-flex justify-content-end align-items-center">
                                        <div className="d-flex align-items-center">
                                            {(!groups.length &&
                                                !formikArg.initialValues.tournamentGroups
                                                    ?.length && (
                                                    <CustomButton
                                                        variant="outline-dark"
                                                        btnText={t(buttonPrefix + 'generate')}
                                                        cb={() => {
                                                            dispatch(
                                                                generateGroups(
                                                                    currentClassId as number
                                                                )
                                                            );
                                                        }}
                                                        className="d-flex justify-content-between align-items-center me-1"
                                                    >
                                                        <AddIcon />
                                                    </CustomButton>
                                                )) ||
                                                null}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-end fw-lighter">
                                        <p>{t(prefix + 'warning8')}</p>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="my-3 d-flex justify-content-between align-items-center">
                                    <h4 className="font-weight-bold">{t(prefix + 'groups')}</h4>
                                    <div className="d-flex align-items-center">
                                        {(!groups.length &&
                                            !formikArg.initialValues.tournamentGroups?.length && (
                                                <CustomButton
                                                    variant="outline-dark"
                                                    btnText={t(buttonPrefix + 'generate')}
                                                    cb={() => {
                                                        dispatch(
                                                            generateGroups(currentClassId as number)
                                                        );
                                                    }}
                                                    className="d-flex justify-content-between align-items-center me-1"
                                                >
                                                    <AddIcon />
                                                </CustomButton>
                                            )) ||
                                            null}
                                        <CustomButton
                                            variant="outline-dark"
                                            btnText={t(buttonPrefix + 'addGroup')}
                                            cb={() => {
                                                push({
                                                    id: uniqId(),
                                                    name: 'Group name ' + (groups.length + 1),
                                                    teams: [],
                                                });
                                            }}
                                            className="d-flex justify-content-between align-items-center"
                                        >
                                            <AddIcon />
                                        </CustomButton>
                                    </div>
                                </div>
                                {groups.length
                                    ? groups.map((group, index) => {
                                          return (
                                              <div
                                                  key={group.id}
                                                  className="container-fluid px-0 my-3"
                                              >
                                                  <Accordion>
                                                      <Card>
                                                          <Card.Header className="p-0 bg-white border-0">
                                                              <CustomAccordionHeader
                                                                  eventKey={index.toString()}
                                                              >
                                                                  <InputGroup>
                                                                      <Form.Text className="mt-0">
                                                                          <div className="d-flex p-2 h-100 flex-column justify-content-center">
                                                                              <GroupIcon
                                                                                  h="25"
                                                                                  w="25"
                                                                              />
                                                                          </div>
                                                                      </Form.Text>
                                                                      <FormTextField
                                                                          className="py-0 border-1 w-75 border-white"
                                                                          name={`tournamentGroups.${index}.name`}
                                                                          index={index}
                                                                          placeholder={t(
                                                                              inputPrefix +
                                                                                  'placeholderGroupName'
                                                                          )}
                                                                      />
                                                                  </InputGroup>
                                                              </CustomAccordionHeader>
                                                          </Card.Header>
                                                          <Accordion.Collapse
                                                              eventKey={index.toString()}
                                                          >
                                                              <Card.Body className="p-2">
                                                                  <div className="d-flex flex-wrap flex-column flex-lg-row">
                                                                      <div className="col-lg-4 d-flex flex-column flex-lg-row align-items-end align-items-lg-start px-0 px-lg-2">
                                                                          <CustomButton
                                                                              className="d-flex justify-content-evenly align-items-center"
                                                                              variant="outline-dark"
                                                                              btnText={t(
                                                                                  buttonPrefix +
                                                                                      'addTeams'
                                                                              )}
                                                                              cb={() =>
                                                                                  handleOpenModal(
                                                                                      group
                                                                                  )
                                                                              }
                                                                          >
                                                                              <AddIcon />
                                                                          </CustomButton>
                                                                      </div>
                                                                      <FieldArray
                                                                          name={`tournamentGroups.${index}.teams`}
                                                                      >
                                                                          {({
                                                                              remove,
                                                                          }: ArrayHelpers) => (
                                                                              <div className="col px-0">
                                                                                  <div className="d-flex flex-column py-3 py-lg-0">
                                                                                      <p className="mb-0 p-1">
                                                                                          {t(
                                                                                              prefix +
                                                                                                  'teams'
                                                                                          )}
                                                                                      </p>
                                                                                      <div className="d-flex flex-wrap">
                                                                                          {group
                                                                                              .teams
                                                                                              .length ? (
                                                                                              group.teams.map(
                                                                                                  (
                                                                                                      team,
                                                                                                      i
                                                                                                  ) =>
                                                                                                      team ===
                                                                                                      null ? null : (
                                                                                                          <React.Fragment
                                                                                                              key={
                                                                                                                  team.id
                                                                                                              }
                                                                                                          >
                                                                                                              <TeamPlayCard
                                                                                                                  onClick={() => {
                                                                                                                      remove(
                                                                                                                          i
                                                                                                                      );
                                                                                                                  }}
                                                                                                                  team={
                                                                                                                      team
                                                                                                                  }
                                                                                                              />
                                                                                                          </React.Fragment>
                                                                                                      )
                                                                                              )
                                                                                          ) : (
                                                                                              <p className="fw-lighter ps-1">
                                                                                                  {t(
                                                                                                      prefix +
                                                                                                          'chooseTeam'
                                                                                                  )}
                                                                                              </p>
                                                                                          )}
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                      </FieldArray>
                                                                  </div>
                                                                  <div className="d-flex justify-content-start justify-content-lg-end">
                                                                      <CustomButton
                                                                          className="d-flex align-items-center"
                                                                          btnText={t(
                                                                              buttonPrefix +
                                                                                  'removeGroup'
                                                                          )}
                                                                          variant="outline-dark"
                                                                          onClick={() => {
                                                                              remove(index);
                                                                              formikArg.setFieldValue(
                                                                                  'tournamentMatches',
                                                                                  Object.values(
                                                                                      matches
                                                                                  )
                                                                                      .filter(
                                                                                          (match) =>
                                                                                              match
                                                                                                  ?.tournamentGroup
                                                                                                  ?.id !==
                                                                                              group.id
                                                                                      )
                                                                                      .reduce(
                                                                                          (
                                                                                              acc: any,
                                                                                              item
                                                                                          ) => {
                                                                                              acc[
                                                                                                  `${item.id}`
                                                                                              ] = item;
                                                                                              return acc;
                                                                                          },
                                                                                          {}
                                                                                      )
                                                                              );
                                                                          }}
                                                                      >
                                                                          <CloseCircle />
                                                                      </CustomButton>
                                                                  </div>
                                                              </Card.Body>
                                                          </Accordion.Collapse>
                                                      </Card>
                                                  </Accordion>
                                              </div>
                                          );
                                      })
                                    : null}
                            </>
                        )}
                    </>
                ) : (
                    <div className="d-flex justify-content-end fw-lighter">
                        <p>{t(prefix + 'warning3')}</p>
                    </div>
                )
            }
        </FieldArray>
    );
};
