import { useCallback, useMemo } from 'react';
import { ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../hooks/redux';
import { ModalBox } from '../../../../../components';
import { ClassValues } from '../ClassesTag/ClassesTag';
import { ClassTypeEnum } from '../../../../../types/enums';

interface ChangeClassTypeModalData {
    currentClass: ClassValues;
    newType: number;
    onConfirm: (keepTeams: boolean) => void;
}

export const ChangeClassTypeModal = (props: ModalProps) => {
    const { currentClass, newType, onConfirm }: ChangeClassTypeModalData = useAppSelector(
        (state) => state.modals.data
    );
    const { t } = useTranslation();
    const prefix = 'changeClassTypeModal-';

    const isTeamTypeInvolved = useMemo(
        () => currentClass.type === ClassTypeEnum.TEAMS || newType === ClassTypeEnum.TEAMS,
        [currentClass.type, newType]
    );

    const hasDoubleTeams = useMemo(
        () => currentClass.teams?.some((team) => team.users.length > 1),
        [currentClass.teams]
    );

    const isKeepTeamsDisabled = useMemo(
        () => isTeamTypeInvolved || (newType === ClassTypeEnum.SINGLES && hasDoubleTeams),
        [isTeamTypeInvolved, newType, hasDoubleTeams]
    );

    const getWarningMessage = useCallback(() => {
        if (isTeamTypeInvolved) {
            return t(prefix + 'teamTypeWarning');
        }
        if (isKeepTeamsDisabled) {
            return t(prefix + 'singlesTypeWarning');
        }
        return null;
    }, [isTeamTypeInvolved, isKeepTeamsDisabled, t]);

    const handleKeepTeams = useCallback(() => {
        onConfirm(true);
        props.onHide?.();
    }, [onConfirm, props]);

    const handleRemoveTeams = useCallback(() => {
        onConfirm(false);
        props.onHide?.();
    }, [onConfirm, props]);

    const warningMessage = getWarningMessage();

    return (
        <ModalBox
            {...props}
            title={t(prefix + 'title')}
            handleSave={handleKeepTeams}
            btnText={t(prefix + 'keepTeams')}
            isDisabled={isKeepTeamsDisabled}
            centerButton={{
                text: t(prefix + 'removeTeams'),
                onClick: handleRemoveTeams,
            }}
            withoutMinHeight
        >
            <div className="d-flex flex-column gap-3">
                <p>{t(prefix + 'changeTypeMessage')}</p>

                {warningMessage && <div className="alert alert-warning">{warningMessage}</div>}
            </div>
        </ModalBox>
    );
};
